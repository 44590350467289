<template>
    <div class="row">
        <div class="col-md-12"> 
            <textarea style='opacity: 0; position: absolute; left: -9999999px; top: -99999px' id="copyarea"></textarea>   
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-sm-12 col-md-10" style="line-height: 2;">
                            <span class="sort-handle"><i class="fa fa-th mr-3"></i></span>
                            <div class="c_checkbox">
                                <label>
                                    <input type="checkbox" v-if="s_task.pending_completed == 0" v-model="s_task.pending_completed" @change="toggleCompletion">
                                    <input type="checkbox" v-else checked v-model="s_task.pending_completed" @change="toggleCompletion">
                                    <span class="icon"><i class="fa fa-check"></i></span>
                                </label>
                            </div>
                            <span v-bind:class="{'strike-through' : s_task.pending_completed == 1}" @dblclick="editTitle(s_task.id)" v-show="!edit_title">{{s_task.title}}</span>
                            
                            <span v-bind:class="{'strike-through' : s_task.pending_completed == 1}" v-show="edit_title">
                                <input class="form-control title-edit" :id="'title-edit-'+s_task.id" v-model="s_task.title" @blur="updateTitle">
                            </span>

                            <span class="ml-2 badge badge-pill badge-primary" v-if="s_task.priority == 'Normal'">Normal</span>
                            <span class="ml-2 badge badge-pill badge-danger" v-if="s_task.priority == 'High'">High</span>
                            <span class="ml-2 badge badge-pill badge-info" v-if="s_task.priority == 'Low'">Low</span>
                        </div>
                        <div class="col-sm-12 col-md-2">
                            <div class="input-group input-group-sm">
                                <select class="form-control ml-auto input-sm" v-model="s_task.owner">
                                    <option v-for="user in allusers" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body" v-bind:class="{'collapse': s_task.note == '' && hide || s_task.note == null && hide}">
                    <!--<p class="mb-2" v-if="s_task.note !== ''">{{s_task.note}}</p>-->
                    <div>
                        <text-editor :content="s_task.note" ref="text_editor"></text-editor>
                    </div>
                    <table class="table table-striped" v-if="!hide">
                        <!--<tbody v-sortable="{handle: '.child-sort-handle', onUpdate:sortUpdate}">-->
                        <draggable v-model="subtasks" tag="tbody" v-bind="dragOptions" @end="sortUpdate">
                            <tr v-for="child in subtasks" v-bind:key="child.id">
                                <td><span class="child-sort-handle"><i class="fa fa-th mr-3"></i></span></td>
                                <td>
                                    <div class="c_checkbox" v-if="child.pending_completed !== undefined">
                                        <label>
                                            <input type="checkbox" v-if="child.pending_completed == 0" v-model="child.pending_completed" @change="toggleChildCompletion(child)">
                                            <input type="checkbox" v-else checked v-model="child.pending_completed" @change="toggleChildCompletion(child)">
                                            <span class="icon"><i class="fa fa-check"></i></span>
                                        </label>
                                    </div>
                                    
                                    <div class="c_checkbox" v-else>
                                        <label>
                                            <input type="checkbox" v-if="child.pendin_complete == 0" v-model="child.pendin_complete" @change="toggleChildCompletion(child)">
                                            <input type="checkbox" v-else checked v-model="child.pendin_complete" @change="toggleChildCompletion(child)">
                                            <span class="icon"><i class="fa fa-check"></i></span>
                                        </label>
                                    </div>
                                </td>
                                <td v-bind:class="{'strike-through' : child.pending_completed == 1 || child.pendin_complete == 1}">
                                    <span v-if="child.title !== undefined">{{child.title}}</span>
                                    <span v-else>{{child.part_name}}</span>
                                    <div class="my-2 child_note" v-if="child.note" v-html="wrapURL(child.note, true)"></div>
                                    <div class="mb-2 subtask_notes" style="display:none">
                                        <textarea v-model="child.note" class="form-control" @blur="updateChildNote($event,  child)"></textarea>
                                    </div>
                                </td>
                                <td>
                                    <ul class="ml-4 d-inline-block inline-list">
                                        <li><button class="btn btn-sm" title="Add/Edit Notes" @click="addSubtaskNotes($event)"><i class="fa fa-pen-square"></i></button></li>
                                        <li><button class="btn btn-sm" title="" @click="deleteSubTask(child)"><i class="fa fa-trash-alt"></i></button></li>
                                    </ul>
                                </td>
                                <td>
                                    <div class="input-group input-group-sm">
                                        <select class="form-control ml-auto input-sm" v-model="child.owner" @change="changeChildOwner(child)">
                                            <option v-for="user in allusers" :value="user.id" :key="user.id">{{user.nicename}}</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </draggable>
                    </table>
                </div>

                <div class="card-footer d-flex">
                    <div class="new_sub_task" v-show="new_sub_task">
                        <div>
                            <div class="input-group input-group-sm">
                                <input placeholder="Add Subtask" class="form-control" @blur="addSubtask" v-model="subtask_title">
                            </div>
                        </div>
                    </div>

                    <div class="buttons">
                        <ul class="inline-list">
                            <li>
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Add Subtask" @click="new_sub_task = !new_sub_task"><i class="fa fa-list"></i></button>
                            </li>
                            <li>
                                <button class="btn btn-sm ld-over text-info" @click="copyTasks($event)" data-toggle="tooltip" data-placement="top" title="Copy Tasks">
                                    <i class="fa fa-copy"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>
                            <li v-if="s_task.job_id">
                                <a target="_blank" :href="'http://darkside-developments.com/jobs/jobsheet1.php?x='+s_task.job_id+'#jobCard'" class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Go to jobcard"><i class="fa fa-external-link-alt"></i></a>
                            </li>
                            <li v-if="hide && subtasks &&  subtasks.length > 0">
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Show Subtasks" @click="toggleSubtasks"><i class="fa fa-plus-circle"></i></button>
                            </li>
                            <li v-else-if="!hide && subtasks && subtasks.length > 0">
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Hide Subtasks" @click="toggleSubtasks"><i class="fa fa-minus-circle"></i></button>
                            </li>
                            <li>
                                <button class="btn btn-sm text-success" data-toggle="tooltip" data-placement="top" title="Edit Task" @click="editTask"><i class="fa fa-pen-square"></i></button>
                            </li>
                            <li>
                                <button class="btn btn-sm text-danger" data-toggle="tooltip" data-placement="top" title="Delete Task" @click="deleteTask"><i class="fa fa-trash-alt"></i></button>
                            </li>
                            <li v-if="subtasks.length > 0">
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Uncomplete Subtasks" @click="uncompleteTasks"><i class="fas fa-redo"></i></button>
                            </li>
                            <li class="all-time-spent"><span class="timespent_modal" data-toggle="modal" :data-target="'#tasktimes-'+s_task.id"><i class="ml-4 mr-1 far fa-clock"></i> {{msToTime(totalTime)}}</span></li>
                        </ul>
                    </div>
                    <div class="buttons ml-auto">
                        <ul class="inline-list">
                            <li v-if="foundTaskTime">
                                <button class="btn btn-sm ld-over" title="" @click="pauseTime($event,s_task.id)">
                                    <i class="fa fa-pause"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>
                            <li v-else>
                                <button class="btn btn-sm ld-over" title="" @click="playTime($event,s_task.id)">
                                    <i class="fa fa-play"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>

                            <li v-if="foundTaskTime">
                                <p class="mb-0">
                                    {{timeSpent}}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" :id="'tasktimes-'+s_task.id" tabindex="-1" role="dialog" :aria-labelledby="'tasktimesLabel-'+s_task.id" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" :id="'tasktimesLabel-'+s_task.id">Task Times</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <th>Start</th>
                                    <th>Pause</th>
                                    <th>Spent</th>
                                    <th>User</th>
                                    <th>Task List</th>
                                </thead>
                                <tbody>
                                    <tr v-for="time in s_task.times" v-bind:key="time.id">
                                        <td>{{time.start_time | date}}</td>
                                        <td>{{time.pause_time | date}}</td>
                                        <td>{{totalTaskTime(time.start_time, time.pause_time)}}</td>
                                        <td>{{time.user | username(allusers)}}</td>
                                        <td>{{time.task_list | username(allusers)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <span class="mr-auto"><i class="ml-4 mr-1 far fa-clock"></i> {{msToTime(totalTime)}}</span>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['task', 'allusers', 'current_user'],
        beforeMount(){
            this.s_task = this.task;
            this.subtasks = this.s_task.subtasks.concat(this.s_task.jobparts);
            this.subtasks.sort( order );
        },
        mounted() {
            $('[data-toggle="tooltip"]').tooltip({
                delay: { "show": 500, "hide": 100 }
            });
            if(localStorage.lastOpenedTask == this.s_task.id) {
                this.hide = false;
            }
        },
        updated: function () {
            var self = this;
        },
        created: function(){
        },
        data: function () {
            return {
                dragOptions: {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost",
                    dragClass: "drag",
                    forceFallback: true,
                    handle: ".child-sort-handle"
                    // fallbackOnBody: true,
                },
                playing: false,
                hide: true,
                timeSpent: 0,
                s_task: null,
                stoppedTask: false,
                editor: null,
                edit_title: false,
                subtask_title: "",
                new_sub_task : false,
                add_sub_task_notes: false,
                subtasks: [],
                totalTime: 0,
                foundTaskTime: false
            }
        },
        watch: {
            s_task: function(newVal){
                var self = this;
                this.s_task.subtasks = newVal.subtasks.sort(compare)
                this.s_task.times = newVal.times.sort(function(a,b){
                    return new Date(b.start_time.replace(/\s/, 'T')) - new Date(a.start_time.replace(/\s/, 'T'));
                });

                if(this.s_task.times) {
                    this.foundTaskTime = this.s_task.times.find(function(element) {
                        return element.user == self.current_user && element.pause_time == null;
                    });
                    if(this.foundTaskTime) {
                        this.playing = true;
                        this.getTimeSpent(this.foundTaskTime.start_time, this.foundTaskTime.pause_time);
                    } else {
                        this.playing = false;
                    }
                } else {
                    this.playing = false;
                }
                
                
                this.totalTime = 0;
                this.s_task.times.forEach(element => {
                    var start = new Date(element.start_time.replace(/\s/, 'T')).getTime();
                    var end;
                    if(element.pause_time == null) {
                        end = new Date().getTime();
                    } else {
                        end = new Date(element.pause_time.replace(/\s/, 'T')).getTime();
                    }
                    this.totalTime += (end - start);
                });

                
            }
        },
        methods: {
            updateTask: function(){
                var self = this;
                axios.get(api_url + '/api/task/'+self.s_task.id).then(response => {
                    self.s_task = response.data;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort( order );
                    if(response.data.times && response.data.times.pause_time == null) {
                        self.playing = true;
                    } else {
                        self.playing = false;
                    }
                    self.$forceUpdate();
                }).catch(e => {
                    this.$toasted.error( 'Failed to get task!', {icon:'exclamation-triangle'});
                });
            },
            getTimeSpent: function(start, end) {
                var self = this;
                var spentTime = 0;
                setInterval(() => {
                    if(self.playing == true) {
                        var endT = null;
                        var startT = null;
                        if(end == null){
                            endT = new Date();
                        } else {
                            endT = new Date(end.replace(/\s/, 'T'));
                        }
                        if(start == null){
                            startT = new Date();
                        } else {
                            startT = new Date(start.replace(/\s/, 'T'));
                        }
                        var time_start = startT.getTime();
                        var time_end = endT.getTime();
                        var diffMs = (time_end - time_start);

                        self.timeSpent = self.msToTime(diffMs);
                    }
                }, 500);
            },
            msToTime: function(s) {
                // Pad to 2 or 3 digits, default is 2
                function pad(n, z) {
                    z = z || 2;
                    return ('00' + n).slice(-z);
                }

                var ms = s % 1000;
                s = (s - ms) / 1000;
                var secs = s % 60;
                s = (s - secs) / 60;
                var mins = s % 60;
                var hrs = (s - mins) / 60;

                return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
            },
            pauseTime: function(event,id){
                var self = this;
                this.toggleLoader(event, 'start');
                const formData = new FormData();
                formData.append('id', id);
                formData.append('user', document.head.querySelector('meta[name="xx-user-id"]').content);
                formData.append('taskList', document.head.querySelector('meta[name="xx-user-id"]').content);
                axios.post(api_url + '/api/task/pause', formData, {
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded',
                    }
                }).then(response => {
                    self.s_task = response.data;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort( order );
                    self.playing = false;
                    self.toggleLoader(event, 'stop');
                })
                .catch(e => {
                    self.toggleLoader(event, 'stop');
                    this.$toasted.error( 'Failed to pause task!', {icon:'exclamation-triangle'});
                })
            },
            playTime: function(event, id){
                var self = this;
                if(self.playing == false) {
                    this.toggleLoader(event, 'start');
                    const formData = new FormData();
                    formData.append('id', id);
                    formData.append('user', document.head.querySelector('meta[name="xx-user-id"]').content);
                    formData.append('taskList', document.head.querySelector('meta[name="xx-user-id"]').content);
                    axios.post(api_url + '/api/task/play', formData, {
                        headers: {
                            'Content-type': 'application/x-www-form-urlencoded',
                        }
                    }).then(response => {
                        self.s_task = response.data;
                        //self.getTimeSpent(self.s_task.times[0].start_time, self.s_task.times[0].pause_time);
                        self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                        self.subtasks.sort( order );
                        self.playing = true;
                        self.toggleLoader(event, 'stop');
                    })
                    .catch(e => {
                        self.toggleLoader(event, 'stop');
                        this.$toasted.error( 'Failed to start task!', {icon:'exclamation-triangle'});
                    })
                }
            },
            sortUpdate: function(event){
                var self = this;
                /*var oldIndex = event.oldIndex;
                var newIndex = event.newIndex;
                this.subtasks.splice(event.newIndex, 0, this.subtasks.splice(event.oldIndex, 1)[0]);*/
                axios.post(api_url + '/api/task/subtasks/reorder',{
                    sort: self.subtasks
                }).then(response => {
                    self.updateTask();
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to update subtask order!', {icon:'exclamation-triangle'});
                })
            },
            toggleCompletion: function(){
                var self = this;
                axios.post(api_url + '/api/task/status',{
                    task: self.task
                }).then(response => {
                    self.playing = false;
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to change status!', {icon:'exclamation-triangle'});
                })
            },
            toggleChildCompletion: function(child){
                var self = this;
                child.task_id = self.s_task.id;
                axios.post(api_url + '/api/task/subtask/update/completion',{
                    subtask: child
                }).then(response => {
                    self.playing = false;
                    self.s_task = response.data;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort( order );
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to change subtask status!', {icon:'exclamation-triangle'});
                })
            },
            editTask: function(){
                this.hide = false;
                this.$refs.text_editor.edit = !this.$refs.text_editor.edit;
            },
            updateNote: function (){
                var self = this;
                axios.post(api_url + '/api/task/update/note',{
                    job_id: self.s_task.job_id,
                    note: self.s_task.note,
                    id: self.s_task.id
                }).then(response => {
                    if(response.data == "Success"){
                        self.hide = true;
                    } else {
                        this.$toasted.error( 'Failed to update!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to update!', {icon:'exclamation-triangle'});
                })
            },
            updateChildNote: function(event, child){
                var self = this;
                child.task_id = this.s_task.id;
                axios.post(api_url + '/api/task/subtask/update/note',{
                    subtask: child
                }).then(response => {
                    self.s_task = response.data;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort( order );
                    var tr = $(event.target).closest('tr');
                    var note_field = tr.find('.subtask_notes');
                    note_field.hide();
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to update subtask note!', {icon:'exclamation-triangle'});
                })
            },
            editTitle: function(id){
                this.edit_title = true;
                var elem = $('#title-edit-'+id);
                setTimeout(function() { elem.focus(); }, 500);
            },
            updateTitle: function (){
                var self = this;
                this.edit_title = false;
                axios.post(api_url + '/api/task/update/title',{
                    title: self.s_task.title,
                    id: self.s_task.id
                }).then(response => {
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to update title!', {icon:'exclamation-triangle'});
                })
            },
            addSubtask: function(){
                var self = this;
                if(self.subtask_title.length > 0) {
                    axios.post(api_url + '/api/task/new/subtask',{
                        owner: 79,
                        title: self.subtask_title,
                        id: self.s_task.id
                    }).then(response => {
                        self.subtask_title = "";
                        self.s_task = response.data;
                        self.s_task.subtasks = response.data.subtasks;
                        self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                        self.subtasks.sort( order );
                        self.subtask_title = "";
                        self.new_sub_task = false;
                        self.hide = false;
                        self.$forceUpdate();
                    })
                    .catch(e => {
                        this.$toasted.error( 'Failed to add subtask!', {icon:'exclamation-triangle'});
                    })
                }
            },
            addJobSubtask: function(){
                var self = this;
                if(self.subtask_title.length > 0) {
                    axios.post(api_url + '/api/task/new/jobtask',{
                        job_id: self.s_task.job_id,
                        title: self.subtask_title,
                        owner: 79
                    }).then(response => {
                        self.subtask_title = "";
                        self.$parent.getTasks();
                        self.hide = false;
                    })
                    .catch(e => {
                        this.$toasted.error( 'Failed to add subtask!', {icon:'exclamation-triangle'});
                    })
                }
            },
            addSubtaskNotes: function(e){
                var tr = $(event.target).closest('tr');
                var note_field = tr.find('.subtask_notes');
                note_field.show();
            },
            deleteTask: function(){
                var conf = confirm('Are you sure you want to delete the task?');
                if(conf) {
                    var self = this;
                    axios.post(api_url + '/api/task/delete',{
                        id: self.s_task.id
                    }).then(response => {
                        if(response.data == "Success") {
                            self.$parent.getTasks();
                        }
                    })
                    .catch(e => {
                        this.$toasted.error( 'Failed to delete task!', {icon:'exclamation-triangle'});
                    })
                }
            },
            deleteSubTask: function(child){
                var conf = confirm('Are you sure you want to delete the subtask?');
                if(conf) {
                    var self = this;
                    child.task_id = this.s_task.id;
                    axios.post(api_url + '/api/task/delete/subtask',{
                        subtask: child
                    }).then(response => {
                        self.s_task = response.data;
                        self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                        self.subtasks.sort( order );
                    })
                    .catch(e => {
                        this.$toasted.error( 'Failed to delete subtask!', {icon:'exclamation-triangle'});
                    })
                }
            },
            changeChildOwner: function(child){
                var self = this;
                child.task_id = this.s_task.id;
                axios.post(api_url + '/api/task/subtask/update/owner',{
                    subtask: child
                }).then(response => {
                    self.s_task = response.data;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort( order );
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to update owner!', {icon:'exclamation-triangle'});
                })
            },
            unescape: function(val) {
                if(val == null && val !== undefined && val !== "") {
                    var el = document.createElement('div');
                    return val.replace(/\&[#0-9a-z]+;/gi, function (enc) {
                        el.innerHTML = enc;
                        return el.innerText
                    });
                } else {
                    return val;
                }
            },
            wrapURL: function (val, new_window) {
                var text = this.unescape(val);
                var url_pattern = /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/ig;
                var target = (new_window === true || new_window == null) ? '_blank' : '';
                
                return text.replace(url_pattern, function (url) {
                    var protocol_pattern = /^(?:(?:https?|ftp):\/\/)/i;
                    var href = protocol_pattern.test(url) ? url : 'http://' + url;
                    return '<a href="' + href + '" target="' + target + '">' + url + '</a>';
                });
            },
            copyTasks: function(event) {
                this.toggleLoader(event, 'start');
                var rowtext = "" ;
                this.subtasks.forEach(element => {
                    if(element.title) {
                        rowtext += element.title + "\r\n";
                    } else {
                        rowtext += element.part_name + "\r\n";
                    }
                });
                this.copyToClipboard(rowtext);
                this.toggleLoader(event, 'stop');
            },
            toggleLoader: function (event, status) {
                var button = event.target.closest('button');
                if(status == "stop") {
                    $(button).removeClass('running');
                } else if(status == "start") {
                    $(button).addClass('running');
                }
            },
            copyToClipboard: function(element) {
                $('#copyarea').val(element);
                $('#copyarea').select();
                document.execCommand("copy");
                //$('#copyarea').val('');
            },
            totalTaskTime: function(start,pause) {
                var start = new Date(start.replace(/\s/, 'T')).getTime();
                var end;
                if(pause == null) {
                    end = new Date().getTime();
                } else {
                    end = new Date(pause.replace(/\s/, 'T')).getTime();
                }
                return this.msToTime(end - start);
            },
            uncompleteTasks: function(){
                var conf = confirm('Are you sure you want to uncomplete all subtasks?');
                if(conf) {
                    var self = this;
                    var id = this.s_task.id;
                    axios.post(api_url + '/api/task/subtask/reset',{
                        id: id
                    }).then(response => {
                        self.s_task = response.data;
                        self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                        self.subtasks.sort( order );
                    })
                    .catch(e => {
                        this.$toasted.error( 'Failed to uncomplete all subtasks!', {icon:'exclamation-triangle'});
                    })
                }
            },
            toggleSubtasks: function(){
                this.hide = !this.hide;
                if(this.hide == true) {
                    localStorage.lastOpenedTask = null;
                } else {
                    localStorage.lastOpenedTask = this.s_task.id;
                }
            }
        },
        filters: {
            username: ((val, users) => {
                var obj = users.find(item=>item.id==val);
                if(obj !== undefined) {
                    return obj.nicename;
                } else {
                    return val;
                }
            }),
            date: ((val) => {
                return moment(val).format("DD-MM-YYYY hh:mm:ss"); 
            })
        }
    }

    function compare(a, b) {
        const orderA = a.order;
        const orderB = b.order;
        
        let comparison = 0;
        if (orderA > orderB) {
            comparison = 1;
        } else if (orderA < orderB) {
            comparison = -1;
        }
        return comparison;
    }

    function order( a, b ) {
        var order_a;
        var order_b;
        if(a.order == undefined) {
            order_a = a.sort_order
        } else {
            order_a = a.order;
        }
        
        if(b.order == undefined) {
            order_b = b.sort_order
        } else {
            order_b = b.order;
        }

        if ( order_a < order_b ){
            return -1;
        }
        if ( order_a > order_b ){
            return 1;
        }
        return 0;
    }
    
</script>

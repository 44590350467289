<template>
    <div class="col-md-12 mb-3 racelist-new">
        <h4 class="mb-3">New Racelist</h4>
        <div class="card">
            <div class="card-body">                        
                <div class="row mb-3">
                    <div class="col-md-12">
                        <label><strong>Title</strong></label>
                        <input class="form-control" placeholder="Title..." v-model="title">
                    </div>    
                </div>

                <div class="form-group">
                    <label class="d-block"><b>Belongs To Job?</b></label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="hasJob2" v-model="hasJob" name="hasJob" value="No" class="custom-control-input">
                        <label class="custom-control-label" for="hasJob2">No</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="hasJob" v-model="hasJob" name="hasJob" value="Yes" class="custom-control-input">
                        <label class="custom-control-label" for="hasJob">Yes</label>
                    </div>
                </div>
                <div class="row mb-3" v-if="hasJob == 'Yes'">
                    <div class="col-md-12">
                        <label><strong>Job</strong></label>
                        <autocomplete
                            aria-label="Search Job"
                            placeholder="Search Job"
                            :search="searchJob"
                            :get-result-value="getResultValueJob"
                            @submit="handleSubmitJob"
                            :debounce-time="500">
                            <template #result="{ result, props }">
                                <li v-bind="props" class="autocomplete-result search-result">
                                    <div class="search-title">{{ result.id }} - {{result.customer.firstname}} {{result.customer.lastname}} </div>
                                    <div class="search-snippet">{{result.vehicle.VRMake}} {{result.vehicle.VRModel}} ({{result.vehicle.VRVrm}})</div>
                                </li>
                            </template>
                        </autocomplete>
                    </div>    
                </div>

                <div v-if="hasJob == 'No'">
                    <div class="form-group">
                        <label class="d-block"><b>Belongs To Vehicle?</b></label>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="hasVehicle2" v-model="hasVehicle" name="hasVehicle" value="No" class="custom-control-input">
                            <label class="custom-control-label" for="hasVehicle2">No</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="hasVehicle" v-model="hasVehicle" name="hasVehicle" value="Yes" class="custom-control-input">
                            <label class="custom-control-label" for="hasVehicle">Yes</label>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="hasVehicle == 'Yes'">
                        <div class="col-md-12">
                            <label><strong>REG</strong></label>
                            <autocomplete
                                aria-label="Search Reg"
                                placeholder="Search Reg"
                                :search="search"
                                :get-result-value="getResultValue"
                                @submit="handleSubmit"
                                :debounce-time="500">
                                <template #result="{ result, props }">
                                    <li v-bind="props" class="autocomplete-result search-result">
                                        <div class="search-title">{{ result.VRVrm }} ({{result.VRVin}})</div>
                                        <div class="search-snippet">{{result.VRMake}} {{result.VRModel}}</div>
                                    </li>
                                </template>
                            </autocomplete>
                        </div>    
                    </div>

                    <div class="row mb-3" v-if="hasVehicle == 'Yes' && selectedVehicle !== null">
                        <div class="col-md-12">
                            <h6>Selected Vehicle: {{selectedVehicle.VRVrm}}({{selectedVehicle.VRVin}}) {{selectedVehicle.VRMake}} {{selectedVehicle.VRModel}}</h6>
                        </div>
                    </div>
                </div>
                
                <div class="row mb-3">
                    <div class="col-md-12">
                        <label><strong>Notes</strong></label>
                        <note-editor v-model="wysiwyg_content"></note-editor>
                    </div>    
                </div>

                <div class="form-group">
                    <label class="d-block"><b>Add Default Tasks?</b></label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="hasDefaults2" v-model="hasDefaults" name="hasDefaults" value="No" class="custom-control-input">
                        <label class="custom-control-label" for="hasDefaults2">No</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="hasDefaults" v-model="hasDefaults" name="hasDefaults" value="Yes" class="custom-control-input">
                        <label class="custom-control-label" for="hasDefaults">Yes</label>
                    </div>
                </div>

                <div v-if="hasDefaults == 'Yes'">
                    <ul>
                        <li v-for="def in defaults" :key="def.id" class="mb-2">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" :id="'check'+def.id" @change="change($event, def)">
                                <label class="form-check-label" :for="'check'+def.id">{{def.title}}</label>
                            </div>
                            <div v-bind:html="def.note"></div>
                        </li>
                    </ul>
                </div>

                <div class="row mb-3">
                    <div class="col-md-12">
                        <label><strong>Tools Required</strong></label>
                        <note-editor v-model="tools_required"></note-editor>
                    </div>    
                </div>

                <div class="row mb-3">
                    <div class="col-md-12">
                        <button class="btn btn-dark btn-block" @click="add_racelist($event)">Add Racelist</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'underscore';
import moment from 'moment';
export default {
    props: ['users'],
    mounted: function() {
        var self = this;
        this.get_defaults();
        $(document).ready(function() {
            $('#userlist').select2({
                multiple: true,
                placeholder: "Select user/s...",
            });

            $('#userlist').on("select2:select", function (e) {
                self.selectedUsers = $('#userlist').select2('val');
            });
        });
    },
    data: () => ({
        title: "",
        wysiwyg_content: "",
        hasVehicle: 'No',
        hasJob: 'No',
        hasDefaults: 'No',
        reg: "",
        results: [],
        selectedVehicle: null,
        defaults: [],
        job: null,
        tools_required: "",
    }),
    methods: {
        change(e, def) {
            var d = this.defaults.find(el => el.id == def.id);
            d.enabled = e.target.checked;
            this.$forceUpdate();
        },
        get_defaults(){
            axios.get(api_url + '/api/racelist/defaults').then(resp => {
                this.defaults = resp.data;
                this.defaults.forEach(element => {
                    element.enabled = false;
                });
            }).catch(err => {
            })
        },
        add_racelist(e){
            var defs = [];
            var vehicle_id = 0;
            var job_id = 0;
            if(this.hasDefaults == 'Yes') {
                this.hasDefaults = true;
                this.selected.forEach(element => {
                    defs.push(element.id);
                });
            }
            
            if(this.hasJob == 'Yes') {
                this.hasVehicle = false;
                this.hasJob = true;
                job_id = this.job.id
            }
            
            if(this.hasVehicle == 'Yes') {
                this.hasJob = false;
                this.hasVehicle = true;
                if(this.hasVehicle && this.selectedVehicle) {
                    vehicle_id = this.selectedVehicle.ID
                }
            }

            e.target.disabled = true;
            axios.post(api_url + '/api/racelist/new',{
                title: this.title,
                notes: this.wysiwyg_content,
                vehicle_id: vehicle_id,
                job_id: job_id,
                defaults: defs,
                tools_required: this.tools_required,
            }).then(response => {
                e.target.disabled = false;
                if(response.data.status == "Success") {
                    window.location.replace('/racelist/'+response.data.id);
                } else {
                    this.$toasted.error( 'Failed to add racelist!', {icon:'exclamation-triangle'});
                }
            })
            .catch(e => {
                this.$toasted.error( 'Failed to add racelist!', {icon:'exclamation-triangle'});
            })
        },
        search(input) {
            return new Promise(resolve => {
                if (input.length < 3) {
                    return resolve([])
                }

                axios.post(api_url + '/api/racelist/vehicle', {
                    reg: input
                }).then(resp => {
                    resolve(resp.data)
                }).catch(err => {
                    return resolve([])
                })
            })
        },
        searchJob(input) {
            return new Promise(resolve => {
                if (input.length < 3) {
                    return resolve([])
                }

                axios.post(api_url + '/api/racelist/find/job', {
                    q: input
                }).then(resp => {
                    resolve(resp.data)
                }).catch(err => {
                    return resolve([])
                })
            })
        },
        getResultValue(result) {
            return result.VRVrm
        },
        getResultValueJob(result) {
            return result.id
        },
        handleSubmit(val){
            this.selectedVehicle = val;
        },
        handleSubmitJob(val){
            this.job = val;
        }
    },
    computed: {
        selected() {
            return this.defaults ? this.defaults.filter(el => el.enabled == true) : []
        }
    },
    watch: {
        hasJob(val){
            if(val == 'Yes') {
                this.hasVehicle = 'No';
                this.selectedVehicle = null;
                this.reg = "";
            }
        }
    }
}
</script>
<style scoped>
ul {
    list-style: none;
    padding: 0;
    margin:0;
}
.results-box {
    border: 1px solid #ced4da;
}
.results-box ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.results-box ul li {
    padding: 10px;
    transition: all 0.2s;
}
.results-box ul li:hover {
    background: #eee
}
.search-result {
  border-top: 1px solid #eee;
  padding: 16px;
  background: transparent;
}

.search-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
}

.search-snippet {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
}
</style>
<style>
.autocomplete-input {
    display: block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.autocomplete-input:focus,
.autocomplete-input[aria-expanded=true] {
    border-color: rgba(0, 0, 0, .12);
    background-color: #fff;
    outline: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .16)
}

[data-position=below] .autocomplete-input[aria-expanded=true] {
    border-bottom-color: transparent;
    border-radius: 8px 8px 0 0
}

[data-position=above] .autocomplete-input[aria-expanded=true] {
    border-top-color: transparent;
    border-radius: 0 0 8px 8px;
    z-index: 2
}

.autocomplete[data-loading=true]:after {
    content: "";
    border: 3px solid rgba(0, 0, 0, .12);
    border-right-color: rgba(0, 0, 0, .48);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    animation: rotate 1s linear infinite
}

.autocomplete-result-list {
    margin: 0;
    border: 1px solid rgba(0, 0, 0, .12);
    padding: 0;
    box-sizing: border-box;
    max-height: 296px;
    overflow-y: auto;
    background: #fff;
    list-style: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .16)
}

[data-position=below] .autocomplete-result-list {
    margin-top: -1px;
    border-top-color: transparent;
    border-radius: 0 0 8px 8px;
    padding-bottom: 8px
}

[data-position=above] .autocomplete-result-list {
    margin-bottom: -1px;
    border-bottom-color: transparent;
    border-radius: 8px 8px 0 0;
    padding-top: 8px
}

.autocomplete-result {
    cursor: default;
    padding: 12px 12px 12px 48px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjY2NjIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
    background-repeat: no-repeat;
    background-position: 12px
}

.autocomplete-result:hover,
.autocomplete-result[aria-selected=true] {
    background-color: rgba(0, 0, 0, .06)
}
.racelist-new .note-editor2 {
    margin-top: 0 !important;
    padding: 0 !important;
    border: 1px solid rgba(0,0,0,0.2);
}

@keyframes rotate {
    0% {
        transform: translateY(-50%) rotate(0deg)
    }
    to {
        transform: translateY(-50%) rotate(359deg)
    }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Multiview from '../views/Multiview.vue'
import Settings from '../views/Settings.vue'
import Racelist from '../views/Racelist.vue'
import NewRacelist from '../views/racelist/New.vue'
import RacelistDefaults from '../views/racelist/Defaults.vue'
import NewTask from '../views/tasks/New.vue'
import CompletedTasks from '../views/tasks/Completed.vue'
import DeletedTasks from '../views/tasks/Deleted.vue'
import RecurringTask from "../views/tasks/Recurring.vue";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: { requiresAuth: false },
	},
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: { requiresAuth: true },
	},
	{
		path: "/multiview",
		name: "Multiview",
		component: Multiview,
		meta: { requiresAuth: true },
	},
	{
		path: "/settings",
		name: "Settings",
		component: Settings,
		meta: { requiresAuth: true },
	},
	{
		path: "/racelist",
		name: "Racelist",
		component: Racelist,
		meta: { requiresAuth: true },
	},
	{
		path: "/racelist/:id",
		name: "RacelistTask",
		component: Racelist,
		meta: { requiresAuth: true },
	},
	{
		path: "/racelist/new",
		name: "NewRacelist",
		component: NewRacelist,
		meta: { requiresAuth: true },
	},
	{
		path: "/racelist/defaults",
		name: "RacelistDefaults",
		component: RacelistDefaults,
		meta: { requiresAuth: true },
	},
	{
		path: "/task/new",
		name: "NewTask",
		component: NewTask,
		meta: { requiresAuth: true },
	},
	{
		path: "/task/recurring",
		name: "RecurringTask",
		component: RecurringTask,
		meta: { requiresAuth: true },
	},
	{
		path: "/tasks/completed/:id",
		name: "CompletedTasks",
		component: CompletedTasks,
		meta: { requiresAuth: true },
	},
	{
		path: "/tasks/deleted/:id",
		name: "DeletedTasks",
		component: DeletedTasks,
		meta: { requiresAuth: true },
	},
	{
		path: "/:id",
		name: "UserTasks",
		component: Home,
		meta: { requiresAuth: true },
	},
	{
		path: "/task/:id",
		name: "SingleTask",
		component: Home,
		meta: { requiresAuth: true },
	},
];
const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const authRequired = to.meta.requiresAuth; //privatePages.includes(to.path);
	const loggedIn = Cookies.get("user") || false;
	if (authRequired && !loggedIn) {
		localStorage.setItem("redirect", to.fullPath);
		next("/login");
	} else {
		var usr = JSON.parse(loggedIn);
		if(usr.token) {
			axios.defaults.headers.common["Authorization"] = "Bearer " + usr.token;
		}
		next();
	}
});

export default router

<template>
	<div id="app">
        <navbar v-if="($store.state.auth.status.loggedIn && !$router.currentRoute.fullPath.includes('login')) && ($router.currentRoute.query && !$router.currentRoute.query.frompaint)"></navbar>
        <main class="py-4" :class="{'login': !$store.state.auth.status.loggedIn}">
            <router-view />
        </main>
	</div>
</template>
<script>
export default {
    mounted(){
        if(this.$store.state.auth.status.loggedIn && localStorage.getItem("staff") == null) {
            axios.get(api_url + "/api/staff").then((resp) => {
                if(resp !== undefined) {
                    localStorage.setItem("staff", JSON.stringify(resp.data));
                }
            });
        }
    },
    created() {
        axios.interceptors.request.use(
            (config) => {
                return config;
            }, (error) => {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                if(error.response.status === 401) {
                    this.$store.dispatch('auth/logout');
                    location.reload();
                }
            }
        );
    },
    computed: {
        current_user(){
            if(this.$router.currentRoute.params.id) {
                return this.$router.currentRoute.params.id;
            } else {
                if(this.$store.state.auth.user) {
                    return this.$store.state.auth.user.id;
                } else {
                    return null
                }
            }
        }
    }
}
</script>
<style lang="scss">
</style>

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card rectask">
                    <div class="card-header">
                        New Recurring Task
                    </div>
                    <div class="card-body">
                        <div class="alert alert-success my-2" v-if="msg !== ''">{{msg}}</div>
                        <div class="form-group">
                            <label><b>Owner:</b></label>
                            <select class="form-control" v-model="owner">
                                <option value="">Select one...</option>
                                <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
                            </select>
                        </div>
                        
                        <div class="form-group">
                            <label><b>Title:</b></label>
                            <input class="form-control" v-model="title" placeholder="Task title...">
                        </div>
                        
                        <div class="form-group">
                            <label><b>Repeats:</b></label>
                            <select class="form-control" v-model="repeats">
                                <option value="" selected="" disabled="">Choose One</option>
                                <option value="Daily">Daily</option>
                                <option value="Every weekday (Monday to Friday)">Every weekday (Monday to Friday)</option>
                                <option value="Every Monday Wednesday Friday">Every Monday Wednesday Friday</option>
                                <option value="Every Tuesday and Thursday">Every Tuesday and Thursday</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Weekly">Weekly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Yearly">Yearly</option>
                            </select>

                            <div class="mt-1" v-if="repeats == 'Monthly'">
                                <select class="form-control" v-model="date_start">
                                    <option value="" selected="" disabled="">Choose a Date</option>
                                    <option v-for="index in 31" :value="index" v-bind:key="index">{{index}}</option>
                                </select>
                            </div>

                            <div class="mt-1" v-if="repeats == 'Quarterly'">
                                <select class="form-control" v-model="month_start">
                                    <option value="" selected="" disabled="">Choose a Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                            </div>

                            <div class="checkboxes d-flex mt-2" v-if="repeats == 'Weekly'">
                                <div class="custom-control custom-checkbox mr-2">
                                    <input type="checkbox" class="custom-control-input" v-model="days" value="Monday" id="mon">
                                    <label class="custom-control-label" for="mon">Monday</label>
                                </div>
                                
                                <div class="custom-control custom-checkbox mr-2">
                                    <input type="checkbox" class="custom-control-input" v-model="days" value="Tuesday" id="tue">
                                    <label class="custom-control-label" for="tue">Tuesday</label>
                                </div>
                                
                                <div class="custom-control custom-checkbox mr-2">
                                    <input type="checkbox" class="custom-control-input" v-model="days" value="Wednesday" id="wed">
                                    <label class="custom-control-label" for="wed">Wednesday</label>
                                </div>
                                
                                <div class="custom-control custom-checkbox mr-2">
                                    <input type="checkbox" class="custom-control-input" v-model="days" value="Thursday" id="thur">
                                    <label class="custom-control-label" for="thur">Thursday</label>
                                </div>
                                
                                <div class="custom-control custom-checkbox mr-2">
                                    <input type="checkbox" class="custom-control-input" v-model="days" value="Friday" id="fri">
                                    <label class="custom-control-label" for="fri">Friday</label>
                                </div>
                                
                                <div class="custom-control custom-checkbox mr-2">
                                    <input type="checkbox" class="custom-control-input" v-model="days" value="Saturday" id="sat">
                                    <label class="custom-control-label" for="sat">Saturday</label>
                                </div>
                                
                                <div class="custom-control custom-checkbox mr-2">
                                    <input type="checkbox" class="custom-control-input" v-model="days" value="Sunday" id="sun">
                                    <label class="custom-control-label" for="sun">Sunday</label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label><b>Starts:</b></label>
                                <input class="form-control" type="date" v-model="date" placeholder="Starts on...">
                            </div>
                            
                            <div class="col-md-6 form-group">
                                <label><b>Time:</b></label>
                                <input class="form-control" type="time" v-model="time" placeholder="Time...">
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="d-block"><b>Ends:</b></label>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="ends-radio" v-model="ends" name="ends" value="Never" class="custom-control-input">
                                <label class="custom-control-label" for="ends-radio">Never</label>
                            </div>

                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="ends-radio2" v-model="ends" name="ends" value="Date" class="custom-control-input">
                                <label class="custom-control-label" for="ends-radio2">Date</label>
                            </div>

                            <div v-if="ends !== 'Never'">
                                <input type="date" v-model="end" class="form-control mt-2">
                            </div>
                        </div>

                        <div class="form-group">
                            <label><b>Notes:</b></label>
                            <note-editor v-model="notes"></note-editor>
                        </div>

                        <div class="form-group">
                            <button class="btn btn-block btn-secondary" @click="submitTask">Add Task</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['users'],
        data: function () {
            return {
                msg: '',
                ends: 'Never',
                owner: '', 
                date: '',  
                time: '', 
                duration: '',  
                title: '',  
                begin: '', 
                end: '2050-12-12',  
                repeats: '',  
                created_by: '',  
                created: '',  
                notes: '',
                days: [],
                date_start: '',
                month_start: ''
            }
        },
        methods: {
            submitTask(){
                var self = this;
                if(this.ends == "Never") {
                    this.end = '2050-12-12';
                }
                axios.post(api_url + '/api/task/new/recurring', {
                    'owner': self.owner,
                    'date': self.date,
                    'time': self.time,
                    'title': self.title,
                    'begin': new Date(), 
                    'end': self.end,  
                    'repeats': self.repeats,
                    'created': new Date(),  
                    'notes': self.notes,
                    'days': self.days,
                    'month_start': self.month_start,
                    'date_start': self.date_start
                }).then(response => {
                    if(response.data == "Success") {
                        self.msg = "Task Added!";
                        self.ends = 'Never';
                        self.owner = '';
                        self.date = ''; 
                        self.time = '';
                        self.duration = ''; 
                        self.title = '';
                        self.begin = '';
                        self.end = '2050-12-12';
                        self.repeats = '';
                        self.notes = '';
                        self.days = [];
                        self.month_start = '';
                        self.date_start = '';
                    } else {
                        alert('Failed, try again!')
                    }
                })
                .catch(e => {
                    alert('Failed, try again!')
                })
            }
        }
    }
</script>

<template>
<div class="container-fluid">
    <div class="multiview-row no-gutters d-flex">
        <multiview-wrapper 
        :users="staff" 
        :auth="$store.state.auth.user"></multiview-wrapper>
    </div>
</div>
</template>

<script>
export default {
    computed: {
        staff() {
            return JSON.parse(localStorage.getItem('staff')) || []
        }
    }
}
</script>

<style scoped>
html, body {
    height:100%;
    overflow:hidden;
}
#app,main {
    height: 100%;
}
.new-task-wrap {
    width:75%
}
</style>
<template>
    <div class="pb-5 position-relative">
        <loading :active.sync="isLoading" 
        :can-cancel="false"
        loader="LineBar"
        :is-full-page="fullPage"></loading>
        <div class="row my-3">
            <div class="col-md-12">
                <label for="dateRange">Select Date Range</label>
                <input id="dateRange" type="text" class="form-control" name="dateRange">
            </div>
        </div>

        <div id="task_list">
            <div v-for="task in tasks" :key="task.id" class="mb-3">
                <table-task-view :auth="JSON.parse(auth)" :task="task" :allusers="users" :current_user="current_user" ref="user_task"></table-task-view>
                <span class="text-muted">Completed on: {{task.completed_date | date}}</span>
            </div>
        </div>

        <nav aria-label="Page navigation" class="d-flex">
            <ul class="pagination ml-auto">
                <li class="page-item" :class="{'disabled': offset == 0}"><a class="page-link"  @click="GetPage(offset - 10)">Previous</a></li>
                <!--<li class="page-item" v-for="i in maxPage" :class="{'active': i == currentPage}"><a class="page-link" @click="GetPage(i)">{{i}}</a></li>-->
                <li class="page-item" :class="{'disabled': tasks.length < 10}"><a class="page-link" @click="GetPage(offset+10)">Next</a></li>
            </ul>
        </nav>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['users','current_user', 'auth'],
        mounted() {
            this.getTasks();

            var self = this;
            
            $('input[name="dateRange"]').daterangepicker({
                opens: 'right',
                locale: {
                    format: 'DD-MM-YYYY'
                }
            }, function(start, end, label) {
                var start = start.format('YYYY-MM-DD');
                var end = end.format('YYYY-MM-DD');
                self.from = start;
                self.to = end;
                axios.get(api_url + '/api/tasks/'+self.current_user+'/completed/date?start='+start+'&end='+end).then(response => {
                    self.tasks = response.data;
                    self.isLoading = false;
                })
                .catch(e => {
                })
            });
        },
        data: function () {
            return {
                tempTasks: [],
                tasks: [],
                sortOrder: [],
                toggleNewTask: false,
                new_task: "",
                wysiwyg_content: "",
                task_top: true,
                isLoading: true,
                fullPage: true,
                priority: "Normal",
                currentPage: 0,
                maxPage: 0,
                totalTasks: 0,
                from: "",
                to: "",
                offset: 0
            }
        },
        watch: {
        },
        methods: {
            getTasks(){
                var self = this;
                axios.get(api_url + '/api/tasks/'+self.current_user+'/completed').then(response => {
                    self.tasks = response.data;
                    self.isLoading = false;
                })
                .catch(e => {
                })
            },
            GetPage(index){
                this.offset = index;
                var self = this;
                var url;
                if(this.from !== "" && this.to !== ""){
                    url = '/api/tasks/'+self.current_user+'/completed/date?start='+this.from+'&end='+this.to+'&page='+index;
                } else {
                    url = '/api/tasks/'+self.current_user+'/completed?page='+index
                }
                axios.get(url).then(response => {
                    self.tasks = response.data;
                    self.isLoading = false;
                    self.scrollToTop();
                })
                .catch(e => {
                })
            },
            scrollToTop() {
                const c = document.documentElement.scrollTop || document.body.scrollTop;
                if (c > 0) {
                    window.requestAnimationFrame(this.scrollToTop);
                    window.scrollTo(0, c - c / 8);
                }
            },
            onUpdate: function (event) {
                var self = this;
                this.tasks.splice(event.newIndex, 0, this.tasks.splice(event.oldIndex, 1)[0]);
                
                var newOrder = [];
                this.tasks.forEach(element => {
                    newOrder.push(element.id);
                });
                self.sortOrder = newOrder;
                
                axios.post(api_url + '/api/tasks/reorder',{
                    current_user: self.current_user,
                    sort: self.sortOrder
                }).then(response => {
                })
                .catch(e => {
                })

            },
            addTask: function() {
                var self = this;
                var title = this.new_task;
                var desc = this.wysiwyg_content;
                var task_top = this.task_top;
                var task_pos = 'Top';
                if(this.wysiwyg_content == "<p></p>") {
                    desc = "";
                }

                if(task_top == true) {
                    task_pos = 'Top';
                } else {
                    task_pos = 'Bottom';
                }

                axios.post(api_url + '/api/task/new',{
                    current_user: self.current_user,
                    title: title,
                    note: desc,
                    position: task_pos,
                    priority: self.priority
                }).then(response => {
                    if(response.data == "Success"){
                        this.new_task = "";
                        this.wysiwyg_content = "";
                        self.getTasks();
                    }
                })
                .catch(e => {
                })
            },
            removeCompleted: function(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/remove/completed',{
                    current_user: self.current_user,
                }).then(response => {
                    if(response.data == "Success"){
                        self.getTasks();
                        self.toggleLoader(event, 'stop');
                    }
                })
                .catch(e => {
                })
            },
            toggleLoader: function (event, status) {
                var button = event.target.closest('button');
                if(status == "stop") {
                    $(button).removeClass('running');
                } else if(status == "start") {
                    $(button).addClass('running');
                }
            },
        },
        filters:{
            date(val){
                return moment(val).format('DD-MM-YYYY H:mm:ss')
            }
        }
    }
</script>
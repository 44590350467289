<template>
  <div :width="width" widthunit="px" :height="height" heightunit="px" :color="color" class="loader-bar-outer">
    <div :height="height" heightunit="px" :color="color" version="1" class="loader-bar-1"></div>
    <div :height="height" heightunit="px" :color="color" version="2" class="loader-bar-2"></div>
  </div>
</template>

<script>
  export default {
    name: 'dots',
    props: {
      color: {
        type: String,
        default: '#36D7B7'
      },
      height: {
        type: Number,
        default: 12
      },
      width: {
        type: Number,
        default: 150
      }
    }
  }
</script>
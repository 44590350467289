<template>
<div>
    <div class="note-editor2 mt-2">
        <editor-content id="wysiwyg" :editor="editor"/>
    </div>
</div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions'
export default {
    props: ['value'],
    components: {
        EditorContent,
        EditorMenuBar,
        EditorMenuBubble
    },
    data(){
        return {
            editorChange: false,
            editor: new Editor({
                content: this.value,
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                ],
                onUpdate: (e) => {
                    //this.$parent.s_task.note = e.getHTML();
                    var html = e.getHTML();
                    /*if(html !== "" && html !== null) {
                        html = html.replace(/<a\s+href=/gi, '<a target="_blank" href=');
                    }*/
                    this.editorChange = true;
                    this.$emit('input', html)
                },
                onBlur: (e) => {
                    this.$emit('blur', e)
                },
                spellcheck: true
            }),
            linkUrl: null,
            linkMenuIsActive: false
        }
    },
    methods: {
        updateNote() {
            this.$parent.updateNote();
            this.edit = false;
        },
        unescape: function(val) {
            if(val) {
                var el = document.createElement('div');
                return val.replace(/\&[#0-9a-z]+;/gi, function (enc) {
                    el.innerHTML = enc;
                    return el.innerText
                });
            }
        },
        wrapURL: function (val, new_window) {
            var text = this.unescape(val);
            $('a[href^="http://"]').attr('target','_blank');
            $('a[href^="https://"]').attr('target','_blank');
            return text;
        },
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    filters: {
        unescape: function(val) {
            return unescape(val)
        }
    },
    watch: {
        value (val) {
            // so cursor doesn't jump to start on typing
            val = _.unescape(val)
            //if (this.editor && val !== this.value) {
            if (this.editor && !this.editorChange) {
                this.editor.setContent(val, true)
            }
            this.editorChange = false;
        }
    }
}
</script>

<style>

</style>
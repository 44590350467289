<template>
<div v-bind:class="{'py-1 px-3': edit}">
    <div class="note-editor" v-bind:class="{'editing': edit}">
        <!--<editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs, menu }" v-if="edit">
            <div>
                <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl($event, commands.link, linkUrl)">
                    <label>Link</label>
                    <input class="menububble__input form-control" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                    <button class="btn btn-sm exit" @click="linkMenuIsActive = false" type="button">X</button>
                    <button class="menububble__button btn btn-sm remove mt-3" @click="setLinkUrl($event, commands.link, null)" type="button">
                        Remove
                    </button>
                    <button class="menububble__button btn btn-sm mt-3 float-right" type="button" @click="setLinkUrl($event, commands.link, linkUrl)">
                        OK
                    </button>
                    <input type="submit" style="position: absolute; left: -9999px"/>
                </form>

                <div class="menubar">
                    <button class="menubar__button btn btn-sm" title="Bold" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                        <i class="fas fa-bold"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Italic" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
                        <i class="fas fa-italic"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Strikethrough" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
                        <i class="fas fa-strikethrough"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Underline" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
                        <i class="fas fa-underline"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Code" :class="{ 'is-active': isActive.code() }" @click="commands.code">
                        <i class="fas fa-code"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Paragraph" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
                        <i class="fas fa-paragraph"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="H1 Header" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })">H1</button>

                    <button class="menubar__button btn btn-sm" title="H2 Header" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({level: 2 })">H2</button>

                    <button class="menubar__button btn btn-sm" title="H3 Header" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({level: 3 })">H3</button>

                    <button class="menubar__button btn btn-sm" title="Bullet List" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
                        <i class="fas fa-list-ul"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Ordered List" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
                        <i class="fas fa-list-ol"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Quote" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote">
                        <i class="fas fa-quote-left"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Code Block" :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block">
                        CO
                    </button>

                    <button class="menubar__button btn btn-sm" title="Horizontal Rule" @click="commands.horizontal_rule">
                        HR
                    </button>

                    <button class="menubar__button btn btn-sm" title="Undo" @click="commands.undo">
                        <i class="fas fa-undo"></i>
                    </button>

                    <button class="menubar__button btn btn-sm" title="Redo" @click="commands.redo">
                        <i class="fas fa-redo"></i>
                    </button>
                    
                    <button class="menubar__button btn btn-sm" title="Redo" @click="showLinkMenu(getMarkAttrs('link'))" :class="{ 'is-active': isActive.link() }">
                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                        <i class="fas fa-link"></i>
                    </button>

                </div>
            </div>
        </editor-menu-bar>-->
        <editor-content :editor="editor" v-if="edit"/>
        <div v-if="!edit && value" class="py-1 px-3" v-html="wrapURL(value,true)" v-linkified></div>
    </div>
    <button class="my-2 btn btn-sm btn-block green" v-if="edit" @click="updateNote">Update Note</button>
</div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
} from 'tiptap-extensions'
export default {
    props: ['value'],
    components: {
        EditorContent,
        EditorMenuBar,
        EditorMenuBubble
    },
    mounted() {
    },
    data(){
        return {
            edit: false,
            html: '',
            editor: new Editor({
                content: this.escapeHtml(this.value),
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                ],
                onUpdate: (e) => {
                    //this.$parent.s_task.note = e.getHTML();
                    var html = e.getHTML();
                    this.html = e.getHTML();
                    if(html !== "" && html !== null) {
                        //html = html.replace(/<a\s+href=/gi, '<a target="_blank" href=');
                    }
                    this.$emit('input', html)
                },
                onBlur: (e) => {
                    this.$emit('blur', e)
                }
            }),
            linkUrl: null,
            linkMenuIsActive: false
        }
    },
    methods: {
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
        hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(event, command, url) {
            event.stopPropagation();
            command(
                { 
                    href: url,
                    target: '_blank'
                }
            )
            this.hideLinkMenu();
            this.editor.focus();
        },
        updateNote() {
            this.$emit('input', this.html)
            this.$parent.updateNote();
            this.edit = false;
        },
        unescape: function(val) {
            if(val == null && val !== undefined && val !== "") {
                var el = document.createElement('div');
                return val.replace(/\&[#0-9a-z]+;/gi, function (enc) {
                    el.innerHTML = enc;
                    return el.innerText
                });
            } else {
                return val;
            }
        },
        escapeHtml(text) {
            /*var map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;',
                '/': '%2F'
            };

            if(text !== undefined) {
                return text.replace(/[&<>"']/g, function(m) { return map[m]; });
            } else {
                return text;
            }*/
            return _.escape(text)
        },
        wrapURL: function (val, new_window) {
            var text = this.unescape(val);
            $('a[href^="http://"]').attr('target','_blank');
            $('a[href^="https://"]').attr('target','_blank');
            return text;
        },
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    filters: {
        unescape: function(val) {
            return unescape(val)
        }
    },
    watch: {
        value (val) {
            // so cursor doesn't jump to start on typing
            if (this.editor && val !== this.value) {
                this.editor.setContent(val, true)
            }
        }
    }
}
</script>

<style>

</style>

<template>
    <div class="pb-5 position-relative">
        <loading :active.sync="isLoading" 
        :can-cancel="false"
        loader="LineBar"
        :is-full-page="fullPage"></loading>

        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header py-1">
                        <div class="new-task-wrap">
                            <input type="text" class="header-input form-control" placeholder="Add Task..." v-model="new_task">
                            <button class="btn btn-sm" v-if="new_task.length > 0" @click="addTask">Add Task</button>
                        </div>
                        <div class="custom-control custom-switch float-right mt-2">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="task_top">
                            <label class="custom-control-label" for="customSwitch1" v-if="task_top">Top</label>
                            <label class="custom-control-label" for="customSwitch1" v-else>Bottom</label>
                        </div>
                        <div class="float-right mr-4 mt-2" @click="toggleNewTask = !toggleNewTask"><i class="fa fa-caret-down"></i></div>
                    </div>
                    <div class="card-body" v-if="toggleNewTask">
                        <div class="form-group">
                            <wysiwyg :content="wysiwyg_content" ref="new_task_desc"></wysiwyg>
                        </div>
                        <div class="form-group mt-2">
                            <label>Priority</label>
                            <select class="form-control" v-model="priority">
                                <option>High</option>
                                <option selected>Normal</option>
                                <option>Low</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--v-sortable="{handle: '.sort-handle', onUpdate:onUpdate}"-->
        <draggable id="task_list" v-model="tasks" v-bind="dragOptions" @end="onUpdate">
            <transition-group>
            <div class="mb-3 ghost" v-for="task in tasks" v-bind:key="task.id">
                <user-task :task="task" :allusers="users" :current_user="current_user" ref="user_task"></user-task>
            </div>
            </transition-group>
        </draggable>

        <!--<footer>
            <div class="container-fluid mt-2">
                <div class="row">
                    <div class="col-md-12">
                        <strong>Total: {{tasks.length}}</strong>
                        <button class="btn btn-sm ml-2 mr-2 green ld-over" @click="removeCompleted($event)">
                            Remove Completed
                            <div class="ld ld-spinner ld-spin"></div>
                        </button>
                        <a href="/task/new" class="btn btn-sm mr-2">Add Task</a>
                    </div>
                </div>
            </div>
        </footer>-->
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['users','current_user'],
        mounted() {
            this.getTasks();
            pace.start({
                "restartOnRequestAfter": true,
                "ajax": false,
                "document": true,
                "eventLag": false
            });
        },
        data: function () {
            return {
                dragOptions: {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost",
                    dragClass: "drag",
                    forceFallback: true,
                    handle: ".sort-handle"
                    // fallbackOnBody: true,
                },
                tempTasks: [],
                tasks: [],
                sortOrder: [],
                toggleNewTask: false,
                new_task: "",
                wysiwyg_content: "",
                task_top: true,
                isLoading: true,
                fullPage: true,
                priority: "Normal"
            }
        },
        watch: {
        },
        methods: {
            getTasks: function(id){
                var self = this;
                axios.get(api_url + '/api/tasks/'+self.current_user).then(response => {
                    if(Array.isArray(response.data)){
                        self.tasks = response.data;
                        self.isLoading = false;
                    } else {
                        this.$toasted.error( 'Failed to get tasks!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                })
            },
            onUpdate: function (evt, originalEvent) {
                var newOrder = [];
                this.tasks.forEach(element => {
                    newOrder.push(element.id);
                });
                self.sortOrder = newOrder;
                
                axios.post(api_url + '/api/tasks/reorder',{
                    current_user: self.current_user,
                    sort: self.sortOrder
                }).then(response => {
                    
                })
                .catch(e => {
                })
                /*var self = this;
                this.tasks.splice(event.newIndex, 0, this.tasks.splice(event.oldIndex, 1)[0]);
                
                var newOrder = [];
                this.tasks.forEach(element => {
                    newOrder.push(element.id);
                });
                self.sortOrder = newOrder;
                
                axios.post(api_url + '/api/tasks/reorder',{
                    current_user: self.current_user,
                    sort: self.sortOrder
                }).then(response => {
                    console.log(response);
                })
                .catch(e => {
                })*/

            },
            addTask: function() {
                var self = this;
                var title = this.new_task;
                var desc = this.wysiwyg_content;
                var task_top = this.task_top;
                var task_pos = 'Top';
                if(this.wysiwyg_content == "<p></p>") {
                    desc = "";
                }

                if(task_top == true) {
                    task_pos = 'Top';
                } else {
                    task_pos = 'Bottom';
                }

                axios.post(api_url + '/api/task/new',{
                    current_user: self.current_user,
                    title: title,
                    note: desc,
                    position: task_pos,
                    priority: self.priority
                }).then(response => {
                    if(response.data == "Success"){
                        this.new_task = "";
                        this.wysiwyg_content = "";
                        self.getTasks();
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to add task!', {icon:'exclamation-triangle'});
                })
            },
            removeCompleted: function(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/remove/completed',{
                    current_user: self.current_user,
                }).then(response => {
                    if(response.data == "Success"){
                        self.getTasks();
                        self.toggleLoader(event, 'stop');
                    } else {
                        this.$toasted.error( 'Failed to remove completed tasks!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to remove completed tasks!', {icon:'exclamation-triangle'});
                })
            },
            toggleLoader: function (event, status) {
                var button = event.target.closest('button');
                if(status == "stop") {
                    $(button).removeClass('running');
                } else if(status == "start") {
                    $(button).addClass('running');
                }
            },
        }
    }
</script>
<template>
<div class="col-md-12 racelist">
    <div class="row">
        <div class="col-md-6" v-if="!hasTaskId">
            <div class="card">
                <div class="card-header px-3 py-2">Race List</div>
                <div class="card-body">
                    <select class="select2" id="taskselect" v-model="task">
                        <option>Select Task...</option>
                        <option v-for="task in tasks" v-bind:key="task.id" :value="task.id">{{task.title}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="col">
            <div class="card">
                <div class="card-header px-3 py-2">User Doing Check</div>
                <div class="card-body">
                    <select class="select2" id="userselect" v-model="check_user">
                        <option>Select User...</option>
                        <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-2" v-if="task">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header px-3 py-2">List Details</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h6><strong>Vehicle</strong></h6>
                            <div v-if="task.job">
                                <h6><strong>Reg: </strong>{{task.job.vehicle.VRVrm}}</h6>
                                <h6><strong>Vin: </strong>{{task.job.vehicle.VRVin}}</h6>
                                <h6><strong>Make: </strong>{{task.job.vehicle.VRMake}}</h6>
                                <h6><strong>Model: </strong>{{task.job.vehicle.VRModel}}</h6>
                            </div>

                            <div v-if="task.vehicle">
                                <h6><strong>Reg: </strong>{{task.vehicle.VRVrm}}</h6>
                                <h6><strong>Vin: </strong>{{task.vehicle.VRVin}}</h6>
                                <h6><strong>Make: </strong>{{task.vehicle.VRMake}}</h6>
                                <h6><strong>Model: </strong>{{task.vehicle.VRModel}}</h6>
                            </div>

                            <div v-if="!task.job && !task.vehicle">No job or vehicle assigned.</div>
                        </div>
                        
                        <div class="col-md-6">
                            <h6><strong>Tools Required</strong></h6>
                            <div v-bind:html="task.tools_required"></div>
                            <div v-if="task.tools_required == '' || task.tools_required == null">No specific tools required.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-2" v-if="task">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header px-3 py-2">
                    <div>
                        <h5 class="d-inline-block"><b>{{task.title}}</b></h5>
                        <ul class="inline-list float-right">
                            <li>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" v-model="checkType" id="inlineRadio1" value="Pre Race">
                                    <label class="form-check-label" for="inlineRadio1">Pre Race</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" v-model="checkType" id="inlineRadio2" value="Post Race">
                                    <label class="form-check-label" for="inlineRadio2">Post Race</label>
                                </div>
                            </li>

                            <li class="all-time-spent" v-if="totalTime != 0"><span class="timespent_modal" data-toggle="modal" :data-target="'#tasktimes-'+task.id"><i class="ml-4 mr-1 far fa-clock"></i> {{msToTime(totalTime)}}</span></li>
                            <li v-if="task.job_id">
                                <a target="_blank" :href="'https://jobs.darkside-developments.com/jobsheet/'+task.job_id" class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Go to jobcard"><i class="fa fa-external-link-alt"></i></a>
                            </li>
                            <li>
                                <button class="btn btn-sm text-success" :class="{'active': !hideNote}" data-toggle="tooltip" data-placement="top" title="Edit/Add Note" @click="editTask"  :disabled="ShouldDisable"><i class="fa fa-pen-square"></i></button>
                            </li>

                            <li class="mr-5">
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top" title="Add Photos" @click="addPhotos" :disabled="ShouldDisable"><i class="fa fa-camera"></i></button>
                            </li>

                            <li v-if="foundTaskTime">
                                <button class="btn btn-sm ld-over" title="" @click="pauseTime($event,task.id)"  :disabled="ShouldDisable">
                                    <i class="fa fa-stop"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>
                            <li v-else>
                                <button class="btn btn-sm ld-over" title="" @click="playTime($event,task.id)"  :disabled="ShouldDisable">
                                    <i class="fa fa-play"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>

                            <li v-if="foundTaskTime">
                                <p class="mb-0">
                                    {{timeSpent}}
                                </p>
                            </li>
                        </ul> 
                    </div>
                    <h6 v-if="task.vehicle">Reg: {{task.vehicle.VRVrm}}</h6>
                    <h6 v-if="task.vehicle">Vin: {{task.vehicle.VRVin}}</h6>
                </div>
                <div class="card-body">
                    <div v-if="task.note" v-html="task.note"></div>
                    <note-editor v-model="task.note" v-if="!hideNote" @blur="updateNote($event)"></note-editor>
                    <hr v-if="task.note" />
                    <draggable v-model="subtasks" tag="ul" class="sublist mt-2" v-bind="dragOptions" @end="sortUpdate">
                        <li v-for="subtask in subtasks" :key="subtask.id">
                            <div class="subtask-main">
                                <span class="child-sort-handle"><i class="fa fa-th mr-3"></i></span>

                                <div class="c_checkbox" v-if="subtask.pending_completed !== undefined">
                                    <label>
                                        <input type="checkbox" v-if="subtask.pending_completed == 0" v-model="subtask.pending_completed" @change="toggleChildCompletion(subtask)" :disabled="ShouldDisable">
                                        <input type="checkbox" v-else checked v-model="subtask.pending_completed" @change="toggleChildCompletion(subtask)" :disabled="ShouldDisable">
                                        <span class="icon"><i class="fa fa-check"></i></span>
                                    </label>
                                </div>

                                <div class="c_checkbox" v-else>
                                    <label>
                                        <input type="checkbox" v-if="subtask.pendin_complete == 0" v-model="subtask.pendin_complete" @change="toggleChildCompletion(subtask)" :disabled="ShouldDisable">
                                        <input type="checkbox" v-else checked v-model="subtask.pendin_complete" @change="toggleChildCompletion(subtask)" :disabled="ShouldDisable">
                                        <span class="icon"><i class="fa fa-check"></i></span>
                                    </label>
                                </div>
                                
                                <input v-bind:class="{'strike-through' : subtask.pending_completed == 1 || subtask.pendin_complete == 1}" class="form-control" v-model="subtask.title" @blur="updateSubtask($event, subtask)" :disabled="ShouldDisable">
                                <ul class="ml-4 d-flex inline-list p-0">
                                    <li class="mr-2">
                                        <button class="btn btn-sm" :class="{'active': subtaskNoteIsVisible(subtask.id)}" title="Add/Edit Notes" @click="addSubtaskNotes(subtask.id)" :disabled="ShouldDisable">
                                            <i class="fa fa-pen-square"></i>
                                        </button>
                                    </li>
                                    <li class="d-inline-block">
                                        <button class="btn btn-sm" title="" @click="deleteSubTask(subtask)" :disabled="ShouldDisable">
                                            <i class="fa fa-trash-alt"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="subtask-notes">
                                <div v-if="subtask.note && !subtaskNoteIsVisible(subtask.id)" v-html="subtask.note"></div>
                                <note-editor v-model="subtask.note" @blur="updateSubtaskNote($event,  subtask)" :id="'subtask-note-'+subtask.id" style="display:none"></note-editor>
                                <hr v-if="subtask.note" />
                            </div>
                        </li>
                    </draggable>
                </div>
                <div class="card-footer d-flex">
                    <div class="new_sub_task w-100">
                        <div>
                            <div class="input-group input-group-sm">
                                <input placeholder="Add Subtask" class="form-control" @blur="addSubtask" v-model="subtask_title" v-on:keyup.enter="addSubtask" :disabled="ShouldDisable">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="images mt-3" v-if="images.length > 0">
                <h6><strong>Images Ready For Upload</strong> - Images will be uploaded on check completion.</h6>
                <span v-for="img in images" :key="img.name" class="mr-2 mb-2">
                    <button style="
                        border: 1px solid red;
                        background: red;
                        color: white;
                        border-radius: 3px;
                    " @click="removeImg(img)">x</button>
                    <img :src="previewImg(img)" style="height: 100px">
                </span>
            </div>

            <button class="mt-3 btn btn-success btn-block" :disabled="check_user == ''" @click="Complete($event)">COMPLETE</button>
        </div>
    </div>
    

    <input type="file" id="photos" name="files[]" accept="image/*" ref="images" @change="NewPhoto" multiple hidden capture="camera">
</div>
</template>

<script>
var timer = 0;
var mouseleft = false;
$(document).mouseleave(function(e){
    mouseleft = true;
});
$(document).mouseenter(function(e){
    mouseleft = false;
});
import moment from 'moment';
export default {
    props:['users', 'tasks'],
    mounted(){
        if(window.location.search !== "") {
            this.check_user = window.location.search.replace("?user=","");
        }
        if(window.location.pathname.split('/').pop() !== "racelist") {
            this.hasTaskId = true;
            this.getTask(window.location.pathname.split('/').pop())
        }

        var self = this;
        $('#userselect').select2({
            multiple: false,
            placeholder: "Select user",
        });
        $('#userselect').on("select2:select", function (e) {
            self.check_user = e.target.value
        });
        
        $('#taskselect').select2({
            multiple: false,
            placeholder: "Select task",
        });
        $('#taskselect').on("select2:select", function (e) {
            self.getTask(e.target.value)
        });

        if(this.check_user !== "") {
            $('#userselect').select2().val(this.check_user).trigger("change");
        }
    },
    data: () => ({
        checkType: 'Pre Race',
        hasTaskId: false,
        dragOptions: {
            animation: 200,
            group: "description",
            disabled: false,
            ghostClass: "ghost",
            dragClass: "drag",
            forceFallback: true,
            handle: ".child-sort-handle"
            // fallbackOnBody: true,
        },
        task: null,
        check_user: "",
        playing: false,
        hide: true,
        hideNote: true,
        timeSpent: 0,
        stoppedTask: false,
        editor: null,
        edit_title: false,
        subtask_title: "",
        new_sub_task : false,
        add_sub_task_notes: false,
        subtasks: [],
        totalTime: 0,
        foundTaskTime: false,
        showOwned: false,
        images: []
    }),
    methods: {
        previewImg(img){
            return window.URL.createObjectURL(img)
        },
        removeImg(img){
            var indx = this.images.findIndex(el => el == img);
            this.images.splice(indx, 1)
        },
        getTask(id){
            axios.get(api_url + '/api/racelist/task/'+id).then((resp) => {
                if(resp.data.id) {
                    this.task = resp.data;
                    this.task.title = this.replace(this.task.title);
                    if(this.task.note) {
                        this.task.note = this.replace(this.task.note);
                    }
                    if(this.task.subtasks) {
                        this.subtasks = this.task.subtasks;
                    }
                    if(this.subtasks) {
                        this.subtasks.sort( order );
                        this.subtasks.forEach(st => {
                            if(st.note) {
                                st.note = this.replace(st.note);
                            }
                            st.part_name = this.replace(st.part_name);
                            st.title = this.replace(st.title);
                            st.hidden = false;
                        })
                    }
                }
            })
        },
        addSubtask(){
            if(this.subtask_title.length > 0) {
                axios.post(api_url + '/api/racelist/new/subtask',{
                    owner: this.check_user,
                    title: this.subtask_title,
                    id: this.task.id
                }).then(response => {
                    this.subtask_title = "";
                    this.task = response.data;
                    this.task.subtasks = response.data.subtasks;
                    this.subtasks = this.task.subtasks.concat(this.task.jobparts);
                    this.subtasks.sort( order );
                    this.subtask_title = "";
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to add subtask!', {icon:'exclamation-triangle'});
                })
            }
        },
        updateSubtaskNote: function(event, child){
            if(mouseleft == true)
            {
                child.task_id = this.task.id;
                axios.post(api_url + '/api/racelist/subtask/update/note',{
                    subtask: child
                }).then(response => {
                })
                .catch(e => {
                })
            } else {
                child.task_id = this.task.id;
                axios.post(api_url + '/api/racelist/subtask/update/note',{
                    subtask: child
                }).then(response => {
                    this.task = response.data;
                    this.subtasks = this.task.subtasks.concat(this.task.jobparts);
                    this.subtasks.sort( order );
                    this.addSubtaskNotes(child.id)
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to update subtask note!', {icon:'exclamation-triangle'});
                })
            }
        },
        deleteSubTask(child){
            var conf = confirm('Are you sure you want to delete the subtask?');
            if(conf) {
                child.task_id = this.task.id;
                axios.post(api_url + '/api/racelist/delete/subtask',{
                    subtask: child
                }).then(response => {
                    this.getTask(this.task.id);
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to delete subtask!', {icon:'exclamation-triangle'});
                })
            }
        },
        updateSubtask: function(event, child) {
            var value = event.target.value;
            var id = child.id;
            var self = this;
            axios.post(api_url + '/api/racelist/subtask/update/title',{
                id: self.task.id,
                subtask: child,
                value: value
            }).then(response => {
                this.getTask(this.task.id);
            })
            .catch(e => {
                this.$toasted.error( 'Failed to update subtask!', {icon:'exclamation-triangle'});
            })
        },
        updateTitle (){
            this.edit_title = false;
            axios.post(api_url + '/api/racelist/update/title',{
                title: this.task.title,
                id: this.task.id
            }).then(response => {
            })
            .catch(e => {
                this.$toasted.error( 'Failed to update title!', {icon:'exclamation-triangle'});
            })
        },
        sortUpdate(event){
            var self = this;
            axios.post(api_url + '/api/racelist/subtasks/reorder',{
                check_user: self.check_user,
                sort: self.subtasks
            }).then(response => {
                self.getTask();
            })
            .catch(e => {
                this.$toasted.error( 'Failed to update subtask order!', {icon:'exclamation-triangle'});
            })
        },
        toggleCompletion(){
            axios.post(api_url + '/api/racelist/status',{
                task: this.task
            }).then(response => {
                this.playing = false;
            })
            .catch(e => {
                this.$toasted.error( 'Failed to change status!', {icon:'exclamation-triangle'});
            })
        },
        toggleChildCompletion(child){
            var self = this;
            child.task_id = self.task.id;
            axios.post(api_url + '/api/racelist/subtask/update/completion',{
                subtask: child
            }).then(response => {
                self.playing = false;
                //self.s_task = response.data;
                //self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                //self.subtasks.sort( order );
            })
            .catch(e => {
                this.$toasted.error( 'Failed to change subtask status!', {icon:'exclamation-triangle'});
            })
        },
        updateNote(e){
            var jobid = null;
            if(this.task.job_id) {
                jobid = this.task.job_id
            }
            if(mouseleft == true){
                axios.post(api_url + '/api/racelist/update/note',{
                    job_id: jobid,
                    note: this.task.note,
                    id: this.task.id
                }).then(response => {
                    if(response.data == "Success"){
                        this.task.note = this.replace(this.task.note);
                    } else {
                        this.$toasted.error( 'Failed to update!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to update!', {icon:'exclamation-triangle'});
                })
            } else {
                axios.post(api_url + '/api/racelist/update/note',{
                    job_id: jobid,
                    note: this.task.note,
                    id: this.task.id
                }).then(response => {
                    if(response.data == "Success"){
                        //self.hide = true;
                        this.hideNote = true;
                        this.task.note = this.replace(this.task.note);
                    } else {
                        this.$toasted.error( 'Failed to update!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to update!', {icon:'exclamation-triangle'});
                })
            }
        },
        GetFileExtension(filename) {
            return /[.]/.exec(filename)
                ? /[^.]+$/.exec(filename)[0]
                : undefined;
        },
        playTime(event, id){
            if(this.playing == false) {
                this.toggleLoader(event, 'start');
                const formData = new FormData();
                formData.append('id', id);
                formData.append('user', this.check_user);
                axios.post(api_url + '/api/racelist/play', {
                    id: id,
                    user: this.check_user
                }).then(response => {
                    this.playing = true;
                    this.toggleLoader(event, 'stop');
                    this.getTask(id)
                })
                .catch(e => {
                    this.toggleLoader(event, 'stop');
                    this.$toasted.error( 'Failed to start task!', {icon:'exclamation-triangle'});
                })
            }
        },
        pauseTime(event,id){
            var conf = confirm('Are you sure you have completed the check?');
            if (conf) {
                var self = this;
                this.toggleLoader(event, 'start');

                var racelist = {
                    "note": this.task.note,
                    "tasks": this.subtasks,
                }

                let formData = new FormData();
                formData.append('id', id); 
                formData.append('completed_by', this.check_user);
                formData.append('task_id', this.task.id);
                formData.append('vehicle_id', this.task.vehicle_id);
                formData.append('title', this.task.title);
                formData.append('type', this.checkType);
                formData.append('details', JSON.stringify(racelist));

                for (var i = 0; i < this.images.length; i++) {
                    this.images[i].ext = this.GetFileExtension(this.images[i].name);
                    formData.append("files[]", this.images[i]);
                }

                axios.post(api_url + '/api/racelist/pause', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then(response => {
                    self.playing = false;
                    self.timeSpent = 0;
                    self.toggleLoader(event, 'stop');
                    location.reload();
                })
                .catch(e => {
                    self.toggleLoader(event, 'stop');
                    this.$toasted.error( 'Failed to pause task!', {icon:'exclamation-triangle'});
                })
            }
        },

        addSubtaskNotes(id){
            var el = document.getElementById('subtask-note-'+id);
            if(el.style.display == 'block') {
                el.style.display = 'none';
            } else {
                el.style.display = 'block';
            }
            this.$forceUpdate();
        },
        subtaskNoteIsVisible(id){
            var el = document.getElementById('subtask-note-'+id);
            if(el) {
                if(el.style.display == 'block') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        editTask(){
            //this.hide = false;
            this.hideNote = !this.hideNote;
            if(this.hideNote == false) {
                setTimeout(() => {
                    $('.ProseMirror').attr("spellcheck",true)
                }, 1000);
            }
        },
        msToTime(ms) {
            function pad(n, amount = 0) {
                if(n < 10) {
                    return 0+n.toFixed(amount);
                } else {
                    return n.toFixed(amount);
                }
            }
            var seconds = ms / 1000;
            // 2- Extract hours:
            var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
            seconds = seconds % 3600; // seconds remaining after extracting hours
            var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
            seconds = seconds % 60;
            return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds, 0);
        },
        replace(s) {
            if(s !== "" && s !== null && s !== undefined) {
                s = s.replace(/Â\s/g,'');
                s = s.replace(/^\s*/g,'');
                s = s.replace(/<p><br><\/p>/g,'');
                s = _.unescape(s);
                $('a[href^="http://"]').attr('target','_blank');
                $('a[href^="https://"]').attr('target','_blank');
                return this.wrapURL(s);
            } else {
                return this.wrapURL(s);
            }
        },
        wrapURL(val, new_window) {
            if(val !== null && val !== "" && val !== undefined) {
                var url_pattern = /((http?|https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/g;
                setTimeout(() => {
                    $('a[href^="http://"]').attr('target','_blank');
                    $('a[href^="https://"]').attr('target','_blank');
                }, 500);

                return val.replace(url_pattern, '<a target="_blank" href="$1">$1</a>');
            } else {
                return val;
            }
        },
        toggleSubtasks(){
            this.hide = !this.hide;
            if(this.hide == true) {
                localStorage.lastOpenedTask = null;
            } else {
                localStorage.lastOpenedTask = this.task.id;
            }
        },
        toggleLoader(event, status) {
            var button = event.target.closest('button');
            if(status == "stop") {
                $(button).removeClass('running');
            } else if(status == "start") {
                $(button).addClass('running');
            }
        },
        escapeHtml(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            var newVal = this.replace(_.unescape(textArea.value));
            return newVal;
            //return text;
        },
        getTimeSpent(start, end, now) {
            var self = this;
            var spentTime = 0;
            var timer = setInterval(() => {
                if(self.playing == true) {
                    var endT = null;
                    var startT = null;
                    if(end == null){
                        endT = moment().tz("Europe/London");
                    } else {
                        endT = moment(end.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
                    }
                    if(start == null){
                        startT = moment().tz("Europe/London");
                    } else {
                        startT = moment(start.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
                    }

                    var a = null;
                    var b = null;
                    if(end == null) {
                        a = endT;
                        b = startT;
                        self.timeSpent = convertTimeHHMMSS(a.diff(b) / 1000);
                    } else {
                        a = endT;
                        b = startT;
                        self.timeSpent = convertTimeHHMMSS(a.diff(b) / 1000);
                    }
                } else {
                    clearInterval(timer);
                }
            }, 500);
        },
        Complete(e){
            e.target.disabled = true;
            var racelist = {
                "note": this.task.note,
                "tasks": this.subtasks,
            }
            axios.post(api_url + '/api/racelist/complete', {
                task_id: this.task.id,
                vehicle_id: this.task.vehicle_id,
                title: this.task.title,
                completed_by: this.check_user,
                details: JSON.stringify(racelist)
            }).then((resp) => {
                if(resp.data.status == "Success") {
                    location.reload();
                }
                e.target.disabled = false;
            })
        },
        addPhotos() {
            var files = document.getElementById('photos');
            files.click();
        },
        NewPhoto() {
            var files = this.$refs.images.files;
            this.images.push(files[0])
        }
    },
    filters: {
        username: ((val, users) => {
            var obj = users.find(item=>item.id==val);
            if(obj !== undefined) {
                return obj.nicename;
            } else {
                return val;
            }
        }),
        date: ((val) => {
            return moment(val).format("DD-MM-YYYY hh:mm:ss");
        })
    },
    watch: {
        task: function(newVal){
            var self = this;
            if(newVal.subtasks) {
                this.task.subtasks = newVal.subtasks.sort(compare)
            }

            if(this.task.started_date !== null && this.task.completed_date == null) {
                this.foundTaskTime = true;
            } else {
                this.foundTaskTime = false;
            }
            if(this.foundTaskTime) {
                this.playing = true;
                this.getTimeSpent(this.task.started_date, this.task.completed_date, moment());
            } else {
                this.playing = false;
            }


            this.totalTime = 0;

            var total = 0;
            this.totalTime = total;
            this.task.note = this.escapeHtml(this.task.note)
        }
    },
    computed: {
        ShouldDisable(){
            if(this.check_user == "" || this.check_user == "Select User...") {
                return true;
            } else {
                return false;
            }
        },
        TasksComplete(){
            var completed = this.subtasks.filter( el => el.pending_completed == 0 || el.pendin_complete == 0);
            if(completed.length == 0) {
                return true;
            } else {
                return false;
            }
        }
    }
}
window.convertTimeHHMMSS = function convertTimeHHMMSS(val) {
    let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
    return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
};
function compare(a, b) {
    const orderA = a.order;
    const orderB = b.order;

    let comparison = 0;
    if (orderA > orderB) {
        comparison = 1;
    } else if (orderA < orderB) {
        comparison = -1;
    }
    return comparison;
}
function order( a, b ) {
    var order_a;
    var order_b;
    if(a.order == undefined) {
        order_a = a.sort_order
    } else {
        order_a = a.order;
    }

    if(b.order == undefined) {
        order_b = b.sort_order
    } else {
        order_b = b.order;
    }

    if ( order_a < order_b ){
        return -1;
    }
    if ( order_a > order_b ){
        return 1;
    }
    return 0;
}
</script>

<style>
.racelist .note-editor2 {
    padding: 0 !important;
    border: 1px solid rgba(0,0,0,0.2);
}
.sublist {
    list-style: none;
    padding: 0;
    margin: 0;
}
.sublist li {
    padding: 0px 0;
}
.sublist .subtask-main {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.form-control.strike-through {
    text-decoration: line-through;
    background: #eee;
    user-select: none;
    pointer-events: none;
}
.subtask-notes {
    padding-left: 61px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
</style>
<template>
    <div class="pb-5 position-relative">
        <loading :active.sync="isLoading" 
        :can-cancel="false"
        loader="LineBar"
        :is-full-page="fullPage"></loading>

        <div id="task_list" v-sortable="{handle: '.sort-handle', onUpdate:onUpdate}">
            <div v-for="task in tasks" class="mb-3" v-bind:key="task.id">
                <user-task :task="task" :allusers="users" ref="user_task"></user-task>
            </div>
        </div>
        <footer>
            <div class="container-fluid mt-2">
                <div class="row">
                    <div class="col-md-12">
                        <strong>Total: {{tasks.length}}</strong>
                        <button class="btn btn-sm ml-2 mr-2 green ld-over" @click="removeCompleted($event)">
                            Remove Completed
                            <div class="ld ld-spinner ld-spin"></div>
                        </button>
                        <button class="btn btn-sm mr-2 hide-sm">Add Recurring Task</button>
                        <button class="btn btn-sm mr-2">Add Task</button>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['users','current_user'],
        mounted() {
            this.getTasks();
        },
        data: function () {
            return {
                tempTasks: [],
                tasks: [],
                sortOrder: [],
                toggleNewTask: false,
                new_task: "",
                wysiwyg_content: "",
                task_top: true,
                isLoading: true,
                fullPage: true,
                priority: "Normal"
            }
        },
        watch: {
        },
        methods: {
            getTasks: function(id){
                var self = this;
                axios.get(api_url + '/api/tasks/'+self.current_user+'/deleted').then(response => {
                    self.tasks = response.data;
                    self.isLoading = false;
                })
                .catch(e => {
                })
            },
            onUpdate: function (event) {
                var self = this;
                this.tasks.splice(event.newIndex, 0, this.tasks.splice(event.oldIndex, 1)[0]);
                
                var newOrder = [];
                this.tasks.forEach(element => {
                    newOrder.push(element.id);
                });
                self.sortOrder = newOrder;
                
                axios.post(api_url + '/api/tasks/reorder',{
                    current_user: self.current_user,
                    sort: self.sortOrder
                }).then(response => {
                })
                .catch(e => {
                })

            },
            addTask: function() {
                var self = this;
                var title = this.new_task;
                var desc = this.wysiwyg_content;
                var task_top = this.task_top;
                var task_pos = 'Top';
                if(this.wysiwyg_content == "<p></p>") {
                    desc = "";
                }

                if(task_top == true) {
                    task_pos = 'Top';
                } else {
                    task_pos = 'Bottom';
                }

                axios.post(api_url + '/api/task/new',{
                    current_user: self.current_user,
                    title: title,
                    note: desc,
                    position: task_pos,
                    priority: self.priority
                }).then(response => {
                    if(response.data == "Success"){
                        this.new_task = "";
                        this.wysiwyg_content = "";
                        self.getTasks();
                    }
                })
                .catch(e => {
                })
            },
            removeCompleted: function(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/remove/completed',{
                    current_user: self.current_user,
                }).then(response => {
                    if(response.data == "Success"){
                        self.getTasks();
                        self.toggleLoader(event, 'stop');
                    }
                })
                .catch(e => {
                })
            },
            toggleLoader: function (event, status) {
                var button = event.target.closest('button');
                if(status == "stop") {
                    $(button).removeClass('running');
                } else if(status == "start") {
                    $(button).addClass('running');
                }
            },
        }
    }
</script>
import axios from "axios";
import Cookies from "js-cookie";
if (window.location.hostname == "localhost") {
	window.api_url = "https://darkside_api.test"
} else {
	window.api_url = "https://api.darkside-developments.com";
}
const Auth_URL = api_url + "/api/auth";

Date.prototype.addHours = function(h) {
	this.setHours(this.getHours() + h);
	return this;
};

class AuthService {
	login(user) {
		return axios
			.post(Auth_URL, {
				username: user.username,
				password: user.password,
			})
			.then((response) => {
				if (response.data.success && response.data.success == true) {
					let access_token = response.data.data.user.token;
					if(access_token !== undefined) {
						axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
					}
					if (window.location.hostname == "localhost") {
						Cookies.set("user", JSON.stringify(response.data.data.user), { expires: new Date().addHours(12), domain: "localhost" });
					} else {
						Cookies.set("user", JSON.stringify(response.data.data.user), { expires: new Date().addHours(12), domain: "darkside-developments.com" });
                    }

                    axios.get(api_url + "/api/staff").then((resp) => {
                        localStorage.setItem("staff", JSON.stringify(resp.data));
					});
				}
				return response.data;
			});
	}

	logout() {
        Cookies.remove("user");
        localStorage.clear();
	}

	register(user) {
		return axios.post(Auth_URL + "signup", {
			username: user.username,
			email: user.email,
			password: user.password,
		});
	}
}

export default new AuthService();

<template>
    <select class="form-control ml-2 input-sm d-inline-block" @change="reload($event)">
        <option value="0">Switch task list...</option>
        <option v-for="user in staff" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
    </select>
</template>

<script>
export default {
    props:['allusers'],
    data: () => ({
        staff: []
    }),
    mounted() {
        this.getStaff();
        setInterval(() => {
            this.getStaff();
        }, 300000);
    },
    methods:{
        getStaff() {
            if(localStorage.getItem('staff')) {
                this.staff = JSON.parse(localStorage.getItem('staff'))
            } else {
                axios.get(api_url + "/api/staff").then((resp) => {
                    if(resp && resp.data) {
                        this.staff = resp.data
                        localStorage.setItem("staff", JSON.stringify(resp.data));
                    }
                });
            }
        },
        reload: function(e) {
            var user = e.target.value;
            if(user != 0) {
                window.location.replace('/'+user);
            }
        }
    }
}
</script>

<style>
.form-control.ml-2.input-sm.d-inline-block {
    width: 200px;
}
</style>

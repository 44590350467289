/*import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import auth from "./modules/auth";
import booking from "./modules/booking";

Vue.use(Vuex)
const dataState = new createPersistedState({
	paths: ["user"],
	key: "user",
	storage: {
		getItem: (key) => localStorage.getItem(key),
		setItem: (key, value) => localStorage.setItem(key, value),
		removeItem: (key) => localStorage.removeItem(key),
	},
});
export default new Vuex.Store({
	modules: {
		user,
		auth,
		booking,
	},
	plugins: [dataState],
});*/
import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";
import user from "./modules/user";
import booking from "./modules/booking";
Vue.use(Vuex);
export default new Vuex.Store({
	modules: {
		auth,
		user,
		booking,
	},
});

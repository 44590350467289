if (window.location.hostname == "localhost") {
	window.api_url = "https://darkside_api.test";
} else {
	window.api_url = "https://api.darkside-developments.com";
}
import pace from './pace';
require('./bootstrap');

import Vue from "vue";
import moment from 'moment';
import Sortable from 'vue-sortable';
import daterangepicker from 'daterangepicker';
import 'daterangepicker/daterangepicker.css';
import Loading from './loader';
import Select2 from 'select2';
import Cookies from 'js-cookie';
import 'select2/dist/css/select2.css';
import './loader/css/index.css';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Toasted from 'vue-toasted';
import draggable from 'vuedraggable';
import tz from 'moment-timezone';
import VueResizable from 'vue-resizable'
import Vue2TouchEvents from 'vue2-touch-events'
import linkify from 'vue-linkify'
import Autocomplete from "@trevoreyre/autocomplete-vue";
import VueCookies from "vue-cookies";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import "./sass/app.scss";


window.pace = pace;
Vue.use(Toasted, { iconPack: 'fontawesome', theme: 'outline', duration: 5000})
Vue.use(Sortable);
Vue.use(Autocomplete);
Vue.use(VueCookies);
Vue.$cookies.config("12h");
Vue.directive("linkified", linkify);
Vue.use(Vue2TouchEvents, {
	longTapTimeInterval: 700,
});
window.select2 = Select2;
var logged_in_user = $("#logged_in_username").text();
var logged_in_id = $("#logged_in_id").text();
/*if (logged_in_user) {
	Raven.setUserContext({
		name: logged_in_user,
		id: logged_in_id,
	});
}*/

import App from "./App.vue";
import store from "./store/index";
import router from "./router/index";
import jwt_decode from 'jwt-decode';
Vue.prototype.$http = axios;
var parse = JSON.parse(Cookies.get("user") || "null");
if (parse) {
	window.token = parse.token;
} else {
	window.token = null;
}

var postMessage = "postMessage";
Vue.prototype.$postMessage = function(message, targetOrigin, target = self) {
	if (!targetOrigin) {
		return;
	}
	if (window[postMessage]) {
		target[postMessage](message, targetOrigin);
	} else {
		console.log("window.postMessage is undefined");
	}
};

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('user-task', require('./components/UserTaskComponent.vue').default);
Vue.component('all-tasks', require('./components/TasksComponent.vue').default);
Vue.component('text-editor', require('./components/TextEditorComponent.vue').default);
Vue.component('note-editor', require('./components/NoteEditorComponent.vue').default);
Vue.component('wysiwyg', require('./components/WYSIWYGComponent.vue').default);
Vue.component('task-settings', require('./components/TaskSettingsComponent.vue').default);
Vue.component('search', require('./components/SearchComponent.vue').default);
Vue.component('completed-tasks', require('./components/CompletedTasksComponent.vue').default);
Vue.component('deleted-tasks', require('./components/DeletedTasksComponent.vue').default);
Vue.component('new-task', require('./components/NewTaskComponent.vue').default);
Vue.component('table-tasks', require('./components/TasksTableComponent.vue').default);
Vue.component('single-task', require('./components/SingleTaskViewComponent.vue').default);
Vue.component('table-task-view', require('./components/TaskTableViewComponent.vue').default);
Vue.component('switch-list', require('./components/SwitchTasklistComponent.vue').default);
Vue.component('remove-completed', require('./components/RemoveCompletedComponent.vue').default);
Vue.component('new-rec-task', require('./components/NewRecurringTaskComponent.vue').default);
Vue.component('race-lists', require('./components/RaceLists.vue').default);
Vue.component('new-race-list', require('./components/NewRacelist.vue').default);
Vue.component('new-default', require('./components/NewDefault.vue').default);
Vue.component('Loading', Loading);
Vue.component('draggable', draggable);
Vue.component('VueResizable', VueResizable);
Vue.component('navbar', require('./components/Navbar.vue').default);
Vue.component('racelistNavbar', require('./components/RacelistNav.vue').default);

Vue.component("multiview-wrapper",require("./components/MultiviewWrapper.vue").default);
Vue.component("multiview-table-tasks",require("./components/MultiviewTasksTableComponent.vue").default);
Vue.component('multiview-table-task-view', require('./components/MultiviewTaskTableViewComponent.vue').default);


var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
var multiview_width = 0;
if (viewportWidth > 1400) {
    multiview_width = 32.5;
} else if (viewportWidth > 992){
    multiview_width = 49.5;
} else {
    multiview_width = 100;
}


const app = new Vue({
	//	el: "#app",
	router,
    store,
	data: {},
	methods: {
		makeResizableDiv(e) {
			var body = document.getElementsByTagName("BODY")[0];
            body.style.userSelect = "none";
            var elem_id = $(e.target)[0].id;
			var parent_elem_id = $(e.target).parent()[0].id;
			const element = document.getElementById(parent_elem_id);
            const resizers = document.getElementById(elem_id);
			const minimum_size = 20;
			let original_width = 0;
			let original_height = 0;
			let original_x = 0;
			let original_y = 0;
			let original_mouse_x = 0;
			let original_mouse_y = 0;

			const currentResizer = resizers;

			e.preventDefault();
			original_width = parseFloat(
				getComputedStyle(element, null)
					.getPropertyValue("width")
					.replace("px", "")
			);
			original_height = parseFloat(
				getComputedStyle(element, null)
					.getPropertyValue("height")
					.replace("px", "")
			);
			original_x = element.getBoundingClientRect().left;
			original_y = element.getBoundingClientRect().top;
			original_mouse_x = e.pageX;
			original_mouse_y = e.pageY;
			window.addEventListener("mousemove", resize);
			window.addEventListener("mouseup", stopResize);

			function resize(e) {
				if (currentResizer.classList.contains("bottom-right")) {
					const width = original_width + (e.pageX - original_mouse_x);
					const height =
						original_height + (e.pageY - original_mouse_y);
					if (width > minimum_size) {
						element.style.width = width + "px";
					}
					if (height > minimum_size) {
						element.style.maxHeight = height + "px";
					}
				} else if (currentResizer.classList.contains("bottom-left")) {
					const height =
						original_height + (e.pageY - original_mouse_y);
					const width = original_width - (e.pageX - original_mouse_x);
					if (height > minimum_size) {
						element.style.maxHeight = height + "px";
					}
					if (width > minimum_size) {
						element.style.width = width + "px";
						element.style.left =
							original_x + (e.pageX - original_mouse_x) + "px";
					}
				} else if (currentResizer.classList.contains("top-right")) {
					const width = original_width + (e.pageX - original_mouse_x);
					const height =
						original_height - (e.pageY - original_mouse_y);
					if (width > minimum_size) {
						element.style.width = width + "px";
					}
					if (height > minimum_size) {
						element.style.maxHeight = height + "px";
						element.style.top =
							original_y + (e.pageY - original_mouse_y) + "px";
					}
				} else {
					const width = original_width - (e.pageX - original_mouse_x);
					const height =
						original_height - (e.pageY - original_mouse_y);
					if (width > minimum_size) {
						element.style.width = width + "px";
						element.style.left =
							original_x + (e.pageX - original_mouse_x) + "px";
					}
					if (height > minimum_size) {
						element.style.maxHeight = height + "px";
						element.style.top =
							original_y + (e.pageY - original_mouse_y) + "px";
					}
				}
			}

			function stopResize() {
				window.removeEventListener("mousemove", resize);
				body.style.userSelect = "auto";
			}
		},
	},
	render: (h) => h(App),
}).$mount("#app");

window.makeResizableDiv = function(div) {
    const element = document.querySelector(div);
    const resizers = document.querySelectorAll(div + ' .resizer')
    const minimum_size = 20;
    let original_width = 0;
    let original_height = 0;
    let original_x = 0;
    let original_y = 0;
    let original_mouse_x = 0;
    let original_mouse_y = 0;
    for (let i = 0; i < resizers.length; i++) {
        const currentResizer = resizers[i];
        currentResizer.addEventListener('mousedown', function (e) {
            e.preventDefault()
            original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
            original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
            original_x = element.getBoundingClientRect().left;
            original_y = element.getBoundingClientRect().top;
            original_mouse_x = e.pageX;
            original_mouse_y = e.pageY;
            window.addEventListener('mousemove', resize)
            window.addEventListener('mouseup', stopResize)
        })

        function resize(e) {
            if (currentResizer.classList.contains('bottom-right')) {
                const width = original_width + (e.pageX - original_mouse_x);
                const height = original_height + (e.pageY - original_mouse_y)
                if (width > minimum_size) {
                    element.style.width = width + 'px'
                }
                if (height > minimum_size) {
                    element.style.height = height + 'px'
                }
            }
            else if (currentResizer.classList.contains('bottom-left')) {
                const height = original_height + (e.pageY - original_mouse_y)
                const width = original_width - (e.pageX - original_mouse_x)
                if (height > minimum_size) {
                    element.style.height = height + 'px'
                }
                if (width > minimum_size) {
                    element.style.width = width + 'px'
                    element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
                }
            }
            else if (currentResizer.classList.contains('top-right')) {
                const width = original_width + (e.pageX - original_mouse_x)
                const height = original_height - (e.pageY - original_mouse_y)
                if (width > minimum_size) {
                    element.style.width = width + 'px'
                }
                if (height > minimum_size) {
                    element.style.height = height + 'px'
                    element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
                }
            }
            else {
                const width = original_width - (e.pageX - original_mouse_x)
                const height = original_height - (e.pageY - original_mouse_y)
                if (width > minimum_size) {
                    element.style.width = width + 'px'
                    element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
                }
                if (height > minimum_size) {
                    element.style.height = height + 'px'
                    element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
                }
            }
        }

        function stopResize() {
            window.removeEventListener('mousemove', resize)
        }
    }
}
window.makeResizableDiv('.resizable');


function getUserString(){
    const user = $cookies.get("user");
	var usr_split = user.split("|");
	var user_obj = {};
	user_obj.id = usr_split[0];
	user_obj.nicename = usr_split[1];
    user_obj.image_path = usr_split[2];
    return user_obj;
}

Sentry.init({
	Vue,
	dsn: "https://1c91cc13403741e8a8eb94abc3144601@sentry.darksidedev.net/18",
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: ["localhost", "https://tasks.darkside-developments.com", /^\//],
		}),
	],
	tracesSampleRate: 0.4
});

<template>
	<div class="vw-100">
		<div class="container">
			<div class="row justify-content-center pt-3">
				<div class="col-md-5">
					<div class="text-center mb-3">
						<img src="assets/images/logo.png" height="50px" />
					</div>
					<div class="card pt-3" id="login-card">
						<div class="card-body">
							<form class="container">
								<div class="form-group">
									<label for="username">Username</label>
									<input
										id="username"
										type="text"
										class="form-control"
										name="username"
										placeholder="Username..."
										required
										autofocus
										v-model="user.username"
									/>
								</div>

								<div class="form-group">
									<label for="password">Password</label>
									<input
										id="password"
										type="password"
										class="form-control"
										name="password"
										required
										autocomplete="current-password"
										placeholder="Password..."
										v-model="user.password"
									/>
								</div>

								<div class="form-check">
									<input
                                        v-model="user.remember"
										class="form-check-input"
										type="checkbox"
										name="remember"
										id="remember"
									/>
									<label
										class="form-check-label"
										for="remember"
										>Remember Me</label
									>
								</div>

								<button
									type="submit"
									id="login-btn"
									class="btn btn-dark btn-block mt-3 mb-3 ld-over"
									@click="login($event)"
								>
									Login
									<div class="ld ld-spinner ld-spin"></div>
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const axios = require("axios");
import User from '../models/user';
export default {
	data() {
		return {
			user: new User('', '', ''),
            loading: false,
            message: ''
		};
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault();
			if (this.password.length > 0) {
				this.toggleLoader(e, "start");
				axios
					.post(api_url + "/api/login", {
						username: this.username,
						password: this.password,
					})
					.then((response) => {
						if (response.data.success == true) {
                            console.log(response);
							localStorage.setItem("user",JSON.stringify(response.data.data.user));
							localStorage.setItem("token",response.data.data.token);
							this.$emit("loggedIn");
							if (this.$route.params.nextUrl != null) {
								location.replace(this.$route.params.nextUrl);
							} else {
								location.replace("/");
							}
						} else {
							this.$toasted.error("Login Failed", {
								icon: "times",
							});
							this.toggleLoader(e, "stop");
						}
					})
					.catch(function(error) {
						this.$toasted.error("Login Failed", { icon: "times" });
						this.toggleLoader(e, "stop");
					});
			}
		},
		login(e) {
            e.preventDefault();
            this.toggleLoader(e, "start");
            this.loading = true;
            if (this.user.username && this.user.password) {
                this.$store.dispatch('auth/login', this.user).then(() => {
                    var redir = localStorage.getItem('redirect');
                    if(redir) {
                        localStorage.removeItem('redirect');
                        this.$router.push(redir);
                    } else {
                        this.$router.push('/');
                    }
                },
                error => {
                    this.loading = false;
                    this.toggleLoader(e, "stop");
                    this.message = (error.response && error.response.data) || error.message || error.toString();
                });
            }
		},
		toggleLoader(event, status) {
			var button = event.target.closest("button");
			if (status == "stop") {
				$(button).removeClass("running");
				$(button).attr("disabled", false);
			} else if (status == "start") {
				$(button).addClass("running");
				$(button).attr("disabled", true);
			}
		},
	},
};
</script>

<style></style>

import Vue from "vue";

const state = {
	booked_in_reg: "",
	vehicle_id: "",
	customer_id: "",
	booked_in_by: "",
	email: "",
	name: "",
	phonenum: "",
	cpref: "Email",
	dep_amount: "",
	dep_ref: "",
	vehicle: "",
	reg: "",
	vin: "",
	engine_code: "Select One",
	engine_code_other: "",
	gearbox: "Select One",
	gearbox_other: "",
	gearbox_type: "Select One",
	gearbox_type_other: "",
	drivetrain: "Select One",
	tun: false,
	ws: false,
	fab: false,
	tvvt: false,
	bodyshop: false,
	ecu_num: "",
	tuned_by: "Select One",
	tuned_by_other: "",
	claimed_power: "",
	claimed_torque: "",
	current_power: "",
	current_torque: "",
	turbocharger: "Select One",
	injectors: "Select One",
	pump: "Select One",
	power_tune: "No",
	map_sensor: "Select One",
	intercooler: "Select One",
	pipework: "Select One",
	camshaft: "Select One",
	cylinder_head: "Select One",
	clutch_flywheel: "Select One",
	turbocharger_other: "",
	injectors_other: "",
	pump_other: "",
	map_sensor_other: "",
	intercooler_other: "",
	pipework_other: "",
	camshaft_other: "",
	cylinder_head_other: "",
	clutch_flywheel_other: "",
	other_mods: "",
	gearbox_mods: "",
	maf: "On",
	egr_hardware: "On",
	egr_software: "On",
	asv: "On",
	cat: "On",
	dpf: "On",
	dpf_sensors: "On",
	swirl_flaps: "On",
	hardcut: "No",
	launch: "No",
	hot_start_fix: "No",
	adblue: "NA",
	left_foot_braking: "NA",
	gearbox_tune: "NA",
	expected_power: "",
	expected_peak_torque: "",
	smoke_level: "No Preference",
	dropoff_date: "",
	dropoff_time: "",
	start_date: "",
	start_time: "",
	finish_date: "",
	finish_time: "",
	collect_date: "",
	collect_time: "",
	customer: "",
	notes: "",
	bhp: "",
	torque: "",
	stopStart: "No",
	parts: [],
};

const mutations = {
	setVehicleId(state, value) {
		state.vehicle_id = value;
	},
	setCustomerId(state, value) {
		state.customer_id = value;
	},
	setBookedInBy(state, value) {
		state.booked_in_by = value;
	},
	setBookedInReg(state, value) {
		state.booked_in_reg = value;
	},
	setEmail(state, value) {
		state.email = value;
	},
	setName(state, value) {
		state.name = value;
	},
	setPhone(state, value) {
		state.phonenum = value;
	},
	setContactPref(state, value) {
		state.cpref = value;
	},
	setDepAmount(state, value) {
		state.dep_amount = value;
	},
	setDepRef(state, value) {
		state.dep_ref = value;
	},
	setVehicle(state, value) {
		state.vehicle = value;
	},
	setReg(state, value) {
		state.reg = value;
	},
	setVin(state, value) {
		state.vin = value;
	},
	setEngineCode(state, value) {
		state.engine_code = value;
	},
	setEngineCodeOther(state, value) {
		state.engine_code_other = value;
	},
	setGearbox(state, value) {
		state.gearbox = value;
	},
	setGearboxOther(state, value) {
		state.gearbox_other = value;
	},
	setGearboxType(state, value) {
		state.gearbox_type = value;
	},
	setGearboxTypeOther(state, value) {
		state.gearbox_type_other = value;
	},
	setDrivetrain(state, value) {
		state.drivetrain = value;
	},
	setTuning(state, value) {
		state.tun = value;
	},
	setWorkshop(state, value) {
		state.ws = value;
	},
	setFabrication(state, value) {
		state.fab = value;
	},
	setTvvt(state, value) {
		state.tvvt = value;
	},
	setBodyshop(state, value) {
		state.bodyshop = value;
	},
	setECUNum(state, value) {
		state.ecu_num = value;
	},
	setTunedBy(state, value) {
		state.tuned_by = value;
	},
	setTunedByOther(state, value) {
		state.tuned_by_other = value;
	},
	setClaimedPower(state, value) {
		state.claimed_power = value;
	},
	setClaimedTorque(state, value) {
		state.claimed_torque = value;
	},
	setCurrentPower(state, value) {
		state.current_power = value;
	},
	setCurrentTorque(state, value) {
		state.current_torque = value;
	},
	setTurbocharger(state, value) {
		state.turbocharger = value;
	},
	setTurbochargerOther(state, value) {
		state.turbocharger_other = value;
	},
	setInjectors(state, value) {
		state.injectors = value;
	},
	setInjectorsOther(state, value) {
		state.injectors_other = value;
	},
	setPump(state, value) {
		state.pump = value;
	},
	setPumpOther(state, value) {
		state.pump_other = value;
	},
	setPowerTune(state, value) {
		state.power_tune = value;
	},
	setMapSensor(state, value) {
		state.map_sensor = value;
	},
	setMapSensorOther(state, value) {
		state.map_sensor_other = value;
	},
	setIntercooler(state, value) {
		state.intercooler = value;
	},
	setIntercoolerOther(state, value) {
		state.intercooler_other = value;
	},
	setPipework(state, value) {
		state.pipework = value;
	},
	setPipeworkOther(state, value) {
		state.pipework_other = value;
	},
	setCamshaft(state, value) {
		state.camshaft = value;
	},
	setCamshaftOther(state, value) {
		state.camshaft_other = value;
	},
	setCylHead(state, value) {
		state.cylinder_head = value;
	},
	setCylHeadOther(state, value) {
		state.cylinder_head_other = value;
	},
	setClutchFlywheel(state, value) {
		state.clutch_flywheel = value;
	},
	setClutchFlywheelOther(state, value) {
		state.clutch_flywheel_other = value;
	},
	setOtherMods(state, value) {
		state.other_mods = value;
	},
	setGearboxMods(state, value) {
		state.gearbox_mods = value;
	},
	setMaf(state, value) {
		state.maf = value;
	},
	setEGRHardware(state, value) {
		state.egr_hardware = value;
	},
	setEGRSoftware(state, value) {
		state.egr_software = value;
	},
	setASV(state, value) {
		state.asv = value;
	},
	setCat(state, value) {
		state.cat = value;
	},
	setDPF(state, value) {
		state.dpf = value;
	},
	setDPFSensors(state, value) {
		state.dpf_sensors = value;
	},
	setSwirlFlaps(state, value) {
		state.swirl_flaps = value;
	},
	setHardcut(state, value) {
		state.hardcut = value;
	},
	setLaunch(state, value) {
		state.launch = value;
	},
	setHotStartFix(state, value) {
		state.hot_start_fix = value;
	},
	setAdblue(state, value) {
		state.adblue = value;
	},
	setLeftFootBraking(state, value) {
		state.left_foot_braking = value;
	},
	setGearboxTune(state, value) {
		state.gearbox_tune = value;
	},
	setExpectedPower(state, value) {
		state.expected_power = value;
	},
	setExpectedPeakTorque(state, value) {
		state.expected_peak_torque = value;
	},
	setSmokeLevel(state, value) {
		state.smoke_level = value;
	},
	setDropoffDate(state, value) {
		state.dropoff_date = value;
	},
	setDropoffTime(state, value) {
		state.dropoff_time = value;
	},
	setStartDate(state, value) {
		state.start_date = value;
	},
	setStartTime(state, value) {
		state.start_time = value;
	},
	setFinishDate(state, value) {
		state.finish_date = value;
	},
	setFinishTime(state, value) {
		state.finish_time = value;
	},
	setCollectDate(state, value) {
		state.collect_date = value;
	},
	setCollectTime(state, value) {
		state.collect_time = value;
	},
	setCustomer(state, value) {
		state.customer = value;
	},
	setNotes(state, value) {
		state.notes = value;
	},
	setBHP(state, value) {
		state.bhp = value;
	},
	setTorque(state, value) {
		state.torque = value;
	},
	setStopStart(state, value) {
		state.stopStart = value;
	},
	setParts(state, value) {
		state.parts = value;
	},
};

export default {
	state,
	mutations,
};

<template>
  <new-rec-task :users="staff"></new-rec-task>
</template>

<script>
export default {
    data: () => ({
        staff: []
    }),
    mounted() {
        this.staff = JSON.parse(localStorage.getItem('staff') || []);
    }
}
</script>

<style>

</style>
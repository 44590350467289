<template>
    <div>
        <h4 class="mb-3">Task Settings</h4>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <strong class="vert-mid">Users in multiview:</strong>
                            </div>
                            <div class="col-md-8">
                                <select class="select2" id="multiuser" v-model="multi_users">
                                    <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-block ld-over" @click="updateMultiUserView($event)">
                                    Update
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <draggable id="task_list" v-model="multiview_order" v-bind="dragOptions" @end="updateMultiUserOrder" tag="ul" ref="multiview_drag_list">
                                    <transition-group ref="transition_list_multiview">
                                        <li class="ghost py-1" v-for="usr in multiview_order" v-bind:key="usr"><span class="sort-handle mr-2"><i class="fa fa-th"></i></span>{{usr | user(users)}}</li>
                                    </transition-group>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <strong class="vert-mid">Default Tasks</strong>
                        <div class="table-responsive">
                            <table class="mt-2 table table-striped">
                                <thead>
                                    <th>Edit</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Link</th>
                                    <th>User</th>
                                    <th>Tuning</th>
                                    <th>Workshop</th>
                                    <th>Fabrication</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr v-for="task in defaultTasks" v-bind:key="task.id">
                                        <td>
                                            <div class="c_checkbox">
                                                <label>
                                                    <input type="checkbox" v-model="task.edit">
                                                    <span class="icon"><i class="fa fa-check"></i></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td v-if="task.edit"><input type="text" class="form-control" v-model="task.Task_name"></td>
                                        <td v-else><strong>{{task.Task_name}}</strong></td>
                                        
                                        <td v-if="task.edit"><input type="text" class="form-control" v-model="task.jobsheet_type"></td>
                                        <td v-else>{{task.jobsheet_type}}</td>

                                        <td v-if="task.edit"><input type="text" class="form-control" v-model="task.Link"></td>
                                        <td v-else><a :href="task.Link" target="_blank">{{task.Link}}</a></td>

                                        <td class="nowrap" v-if="task.edit">
                                            <select v-model="task.default_user" class="form-control">
                                                <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
                                            </select>
                                        </td>
                                        <td v-else>{{task.default_user | user(users)}}</td>

                                        <td>{{task.tuning}}</td>
                                        <td>{{task.workshop}}</td>
                                        <td>{{task.fabrication}}</td>
                                        <td class="nowrap" v-if="task.edit"><button class="btn btn-sm btn-block ld-over" @click="updateDefaultTask($event, task)">
                                            Update
                                            <div class="ld ld-spinner ld-spin"></div>
                                        </button></td>
                                        <td v-else></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <strong class="vert-mid">Recurring Tasks</strong>
                        <a class="ml-2 btn btn-success btn-sm" target="_blank" href="/task/recurring"><i class="fas fa-plus"></i> New Recurring Task</a>
                        <div class="table-responsive">
                            <table class="mt-2 table table-striped">
                                <thead>
                                    <th>Edit</th>
                                    <th>Title</th>
                                    <th>Time</th>
                                    <th>Begin</th>
                                    <th>End</th>
                                    <th>Repeats</th>
                                    <th>User</th>
                                    <th>Notes</th>
                                    <th>Created By</th>
                                    <th></th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr v-for="task in recurringTasks" v-bind:key="task.id">
                                        <td>
                                            <div class="c_checkbox">
                                                <label>
                                                    <input type="checkbox" v-model="task.edit">
                                                    <span class="icon"><i class="fa fa-check"></i></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td width="300px" v-if="task.edit"><textarea style="min-width: 300px" class="form-control" v-model="task.title"></textarea></td>
                                        <td width="300px" v-else><strong>{{task.title}}</strong></td>
                                        
                                        <td class="nowrap" v-if="task.edit"><input type="time" class="form-control" v-model="task.time"></td>
                                        <td class="nowrap" v-else>{{task.time}}</td>

                                        <td class="nowrap" v-if="task.edit"><input type="date" class="form-control" v-model="task.begin"></td>
                                        <td class="nowrap" v-else>{{task.begin}}</td>

                                        <td class="nowrap" v-if="task.edit"><input type="date" class="form-control" v-model="task.end"></td>
                                        <td class="nowrap" v-else>{{task.end}}</td>

                                        <td class="nowrap" v-if="task.edit">
                                            <select v-model="task.repeats" class="form-control">
                                                <option value="Daily">Daily</option>
                                                <option value="Every weekday (Monday to Friday)">Every weekday (Monday to Friday)</option>
                                                <option value="Every Monday Wednesday Friday">Every Monday Wednesday Friday</option>
                                                <option value="Every Tuesday and Thursday">Every Tuesday and Thursday</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Yearly">Yearly</option>
                                            </select>
                                            
                                            
                                            <div class="checkboxes" v-if="task.repeats == 'Weekly' || task.repeats == 'Daily'">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="task.days" value="Monday" id="mon">
                                                    <label class="custom-control-label" for="mon">Monday</label>
                                                </div>
                                                
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="task.days" value="Tuesday" id="tue">
                                                    <label class="custom-control-label" for="tue">Tuesday</label>
                                                </div>
                                                
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="task.days" value="Wednesday" id="wed">
                                                    <label class="custom-control-label" for="wed">Wednesday</label>
                                                </div>
                                                
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="task.days" value="Thursday" id="thur">
                                                    <label class="custom-control-label" for="thur">Thursday</label>
                                                </div>
                                                
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="task.days" value="Friday" id="fri">
                                                    <label class="custom-control-label" for="fri">Friday</label>
                                                </div>
                                                
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="task.days" value="Saturday" id="sat">
                                                    <label class="custom-control-label" for="sat">Saturday</label>
                                                </div>
                                                
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" v-model="task.days" value="Sunday" id="sun">
                                                    <label class="custom-control-label" for="sun">Sunday</label>
                                                </div>
                                            </div>

                                            <div class="mt-1" v-if="task.repeats == 'Monthly'">
                                                <select class="form-control" v-model="task.date_start">
                                                    <option value="" selected="" disabled="">Choose a Date</option>
                                                    <option v-for="index in 31" :value="index" v-bind:key="index">{{index}}</option>
                                                </select>
                                            </div>

                                            <div class="mt-1" v-if="task.repeats == 'Quarterly'">
                                                <select class="form-control" v-model="task.month_start">
                                                    <option value="" selected="" disabled="">Choose a Month</option>
                                                    <option value="January">January</option>
                                                    <option value="February">February</option>
                                                    <option value="March">March</option>
                                                    <option value="April">April</option>
                                                    <option value="May">May</option>
                                                    <option value="June">June</option>
                                                    <option value="July">July</option>
                                                    <option value="August">August</option>
                                                    <option value="September">September</option>
                                                    <option value="October">October</option>
                                                    <option value="November">November</option>
                                                    <option value="December">December</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td class="nowrap" v-else>{{task.repeats}} <span v-if="(task.repeats == 'Weekly' || task.repeats == 'Daily') && task.days !== null && task.days.length > 0">({{task.days}})</span></td>

                                        <td class="nowrap" v-if="task.edit">
                                            <select v-model="task.owner" class="form-control">
                                                <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
                                            </select>
                                        </td>
                                        <td class="nowrap" v-else>{{task.owner | user(users)}}</td>

                                        <td v-if="task.edit"><textarea v-model="task.notes" class="form-control"></textarea></td>
                                        <td v-else>{{task.notes}}</td>

                                        <td class="nowrap">{{task.created_by | user(users)}}</td>
                                        <td class="nowrap" v-if="task.edit"><button class="btn btn-sm btn-block ld-over" @click="updateReccuringTask($event,task)">
                                            Update
                                            <div class="ld ld-spinner ld-spin"></div>
                                        </button></td>
                                        <td v-else></td>
                                        <td><button class="btn btn-sm btn-danger ld-over" @click="removeRecTask($event, task)"><i class="fa fa-trash"></i><div class="ld ld-spinner ld-spin"></div></button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    props: ['users', 'users', 'multiuser_tasks'],
    mounted: function() {
        var self = this;
        //this.defaultTasks = this.default_tasks;
        //this.recurringTasks = this.recurring_tasks;


        //console.log(this.multiuser_tasks)
        this.getMultiviewUsers();
        //this.multi_users = JSON.parse(this.multiuser_tasks);
        //this.multiview_order = this.multi_users;

        axios.get(api_url + '/api/tasks/info/settings').then((resp) => {
            this.recurringTasks = resp.data.recurring;
            this.defaultTasks = resp.data.default;
            this.recurringTasks.forEach(element => {
                //console.log(element)
                if(element.days == null) {
                    element.days = [];
                } else {
                    element.days = JSON.parse(element.days);
                }
            });
        });

        /*$(document).ready(function() {
            setTimeout(() => {
                $('#multiuser').select2({
                    multiple: true,
                    placeholder: "Select users...",
                });

                $("#multiuser").select2().select2('val', [self.multi_users]);

                $('#multiuser').on("select2:select", function (e) {
                    self.multi_users = $('#multiuser').select2('val');
                    self.multiview_order = self.multi_users;
                });
                
                $('#multiuser').on("select2:unselect", function (e) {
                    self.multi_users = $('#multiuser').select2('val');
                    self.multiview_order = self.multi_users;
                });
            }, 500);
        });*/
    },
    data: function(){
        return {
            multi_users: [],
            defaultTasks: [],
            recurringTasks: [],
            multiview_order: [],
            recTasks: [],
            dragOptions: {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                dragClass: "drag",
                forceFallback: true,
                handle: ".sort-handle"
            },
        }
    },
    methods: {
        getMultiviewUsers() {
            var self = this;
            axios.get(api_url + '/api/tasks/multiview').then((resp) => {
                this.multi_users = resp.data;
                this.multiview_order = this.multi_users;
                setTimeout(() => {
                    $('#multiuser').select2({
                        multiple: true,
                        placeholder: "Select users...",
                    });

                    $("#multiuser").select2().select2('val', [self.multi_users]);

                    $('#multiuser').on("select2:select", function (e) {
                        self.multi_users = $('#multiuser').select2('val');
                        self.multiview_order = self.multi_users;
                    });
                    
                    $('#multiuser').on("select2:unselect", function (e) {
                        self.multi_users = $('#multiuser').select2('val');
                        self.multiview_order = self.multi_users;
                    });
                }, 500);
            })
        },
        updateReccuringTask: function(event,task) {
            var self = this;
            this.toggleLoader(event, 'start');
            axios.post(api_url + '/api/task/recurring/update',{
                task:task
            }).then(response => {
                self.recurringTasks = response.data;
                self.recurringTasks.forEach(element => {
                    if(element.days == null) {
                        element.days = [];
                    } else {
                        element.days = JSON.parse(element.days);
                    }
                });
                this.toggleLoader(event, 'stop');
            })
            .catch(e => {
                this.toggleLoader(event, 'stop');
            })
        },
        updateDefaultTask: function(event,task) {
            var self = this;
            this.toggleLoader(event, 'start');
            axios.post(api_url + '/api/task/default/update',{
                task:task
            }).then(response => {
                self.defaultTasks = response.data;
                this.toggleLoader(event, 'stop');
            })
            .catch(e => {
                this.toggleLoader(event, 'stop');
            })
        },
        toggleLoader: function (event, status) {
            var button = event.target.closest('button');
            if(status == "stop") {
                $(button).removeClass('running');
            } else if(status == "start") {
                $(button).addClass('running');
            }
        },
        updateMultiUserView: function(event) {
            var self = this;
            this.toggleLoader(event, 'start');
            axios.post(api_url + '/api/task/multiview/update',{
                value:self.multiview_order
            }).then(response => {
                //this.$store.dispatch('userRequest');
                this.getMultiviewUsers();
                this.toggleLoader(event, 'stop');
            })
            .catch(e => {
                this.toggleLoader(event, 'stop');
            })
        },
        updateMultiUserOrder: function(event) {
            var self = this;
            this.toggleLoader(event, 'start');
            axios.post(api_url + '/api/task/multiview/update',{
                value:self.multiview_order
            }).then(response => {
                //this.$store.dispatch('userRequest');
                this.getMultiviewUsers();
                this.toggleLoader(event, 'stop');
            })
            .catch(e => {
                this.toggleLoader(event, 'stop');
            })
        },
        removeRecTask: function(event, task) {
            var self = this;
            this.toggleLoader(event, 'start');
            axios.post(api_url + '/api/task/recurring/remove',{
                task:task
            }).then(response => {
                self.recurringTasks = response.data;
                self.recurringTasks.forEach(element => {
                    if(element.days == null) {
                        element.days = [];
                    } else {
                        element.days = JSON.parse(element.days);
                    }
                });
                this.toggleLoader(event, 'stop');
            })
            .catch(e => {
                this.toggleLoader(event, 'stop');
            })
        }
    },
    filters: {
        user: ((val, users) => {
            var obj = users.find(item=>item.id==val);
            if(obj) {
                return obj.nicename;
            } else {
                return val;
            }
        }),
        date: ((val) => {
            return moment(val).format("DD-MM-YYYY");
        })
    }
}
</script>
<template>
    <div class="subnav">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link" href="/racelist/new"><i class="fas fa-plus"></i> New Racelist</a>
            </li>
            
            <li class="nav-item">
                <a class="nav-link" href="/racelist/defaults"><i class="fas fa-star"></i> Defaults</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.subnav {
    background: rgb(255, 255, 255);
    width: 100%;
    position: fixed;
    top: 60px;
}
.subnav .navbar-nav {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: space-evenly;
    align-items: center;
}
</style>
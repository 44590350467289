<template>
    <div class="row tableview" :id="s_task.id">
        <div class="col-md-12">
            <textarea style="opacity: 0; position: absolute; left: -9999999px; top: -99999px" id="copyarea"></textarea>
            <div class="card task-card"
                 v-bind:class="{'owned': s_task.owner == current_user, 'completed_task' : s_task.pending_completed == 1}">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-12 col-lg-6 d-flex" style="line-height: 2;">
                            <span class="sort-handle"><i class="fa fa-th mr-3"></i></span>
                            <div class="c_checkbox">
                                <label>
                                    <input type="checkbox" v-if="s_task.pending_completed == 0"
                                           v-model="s_task.pending_completed"
                                           @change="toggleCompletion">
                                    <input type="checkbox" v-else checked v-model="s_task.pending_completed"
                                           @change="toggleCompletion">
                                    <span class="icon"><i class="fa fa-check"></i></span>
                                </label>
                            </div>

                            <div class="text-danger mr-3" v-if="deleted == 1">DELETED</div>

                            <span class="ml-1 mr-2" v-if="hide && subtasks &&  subtasks.length > 0">
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top"
                                        title="Show Subtasks" @click="toggleSubtasks"><i class="fa fa-plus-circle"></i></button>
                            </span>

                            <span class="ml-1 mr-2" v-else-if="!hide && subtasks && subtasks.length > 0">
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top"
                                        title="Hide Subtasks" @click="toggleSubtasks"><i class="fa fa-minus-circle"></i></button>
                            </span>

                            <span class="ml-1 mr-2" v-if="!hide && subtasks && subtasks.length > 0">
                                <button class="btn btn-sm" v-bind:class="{'active': showOwned}" data-toggle="tooltip"
                                        data-placement="top" title="Toggle owned subtasks"
                                        @click="showOwnedTasks(s_task)"><i class="fa fa-user"></i></button>
                            </span>

                            <span style="width:100%" v-bind:class="{'strike-through' : s_task.pending_completed == 1}"
                                  v-show="!edit_title">
                                <input class="form-control title-edit w-100" :id="'title-edit-'+s_task.id"
                                       v-model="s_task.title" @blur="updateTitle">
                            </span>
                        </div>

                        <div class="col-md-12 col-lg-4">
                            <span class="buttons ml-auto">
                                <ul class="inline-list float-right">
                                    <li class="all-time-spent" v-if="totalTime != 0"><span class="timespent_modal"
                                                                                           data-toggle="modal"
                                                                                           :data-target="'#tasktimes-'+s_task.id"><i
                                        class="ml-4 mr-1 far fa-clock"></i> {{ msToTime(totalTime) }}</span></li>
                                    <li>
                                        <button class="btn btn-sm" data-toggle="tooltip" data-placement="top"
                                                title="Add Subtask" @click="new_sub_task = !new_sub_task"><i
                                            class="fa fa-list"></i></button>
                                    </li>
                                    <li v-if="subtasks.length > 0">
                                        <button class="btn btn-sm ld-over text-info" @click="copyTasks($event)"
                                                data-toggle="tooltip" data-placement="top" title="Copy Tasks">
                                            <i class="fa fa-copy"></i>
                                            <div class="ld ld-spinner ld-spin"></div>
                                        </button>
                                    </li>
                                    <li v-if="s_task.job_id">
                                        <a target="_blank"
                                           :href="'https://jobs.darkside-developments.com/jobsheet/'+s_task.job_id"
                                           class="btn btn-sm" data-toggle="tooltip" data-placement="top"
                                           title="Go to jobcard"><i class="fa fa-external-link-alt"></i></a>
                                    </li>
                                    <li>
                                        <button class="btn btn-sm text-success" data-toggle="tooltip"
                                                data-placement="top" title="Edit/Add Note" @click="editTask"><i
                                            class="fa fa-pen-square"></i></button>
                                    </li>
                                    <li>
                                        <button class="btn btn-sm text-danger ld-over" data-toggle="tooltip"
                                                data-placement="top" title="Delete Task" @click="deleteTask">
                                            <i class="fa fa-trash-alt"></i>
                                            <div class="ld ld-spinner ld-spin"></div>
                                        </button>
                                    </li>
                                    <li v-if="subtasks.length > 0">
                                        <button class="btn btn-sm" data-toggle="tooltip" data-placement="top"
                                                title="Uncomplete Subtasks" @click="uncompleteTasks"><i
                                            class="fas fa-redo"></i></button>
                                    </li>

                                    <li v-if="foundTaskTime && foundTaskTime.user == user_id">
                                        <button class="btn btn-sm ld-over" title=""
                                                @click="pauseTime($event,s_task.id)">
                                            <i class="fa fa-pause"></i>
                                            <div class="ld ld-spinner ld-spin"></div>
                                        </button>
                                    </li>
                                    <li v-else>
                                        <button class="btn btn-sm ld-over" title="" @click="playTime($event,s_task.id)">
                                            <i class="fa fa-play"></i>
                                            <div class="ld ld-spinner ld-spin"></div>
                                        </button>
                                    </li>

                                    <li v-if="foundTaskTime && foundTaskTime.user == user_id">
                                        <p class="mb-0">
                                            {{ timeSpent }}
                                        </p>
                                    </li>
                                </ul>
                            </span>
                        </div>

                        <div class="col-md-12 col-lg-2">
                            <div class="input-group input-group-sm">
                                <select class="form-control ml-auto input-sm" v-model="s_task.owner"
                                        @change="changeOwner">
                                    <option v-for="user in users" v-bind:key="user.id" :value="user.id">
                                        {{ user.nicename }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body" v-bind:class="{'collapse': s_task.note == '' && hide }">
                    <div class="my-2 ml-2" v-if="s_task.note !== '' && s_task.note !== '<p></p>'" v-html="s_task.note"
                         v-show="hideNote"></div>
                    <div class="task-notes px-2">
                        <note-editor v-model="s_task.note" @blur="updateNote($event)" v-if="!hideNote"></note-editor>
                    </div>

                    <div class="table-responsive py-1 px-3" v-if="subtasks.length > 0 && !hide">
                        <table class="table table-striped mt-2">
                            <draggable v-model="subtasks" tag="tbody" v-bind="dragOptions" @end="sortUpdate">
                                <tr class="subtask-row" v-for="child in subtasks" v-bind:key="child.id"
                                    v-show="!child.hidden"
                                    v-bind:class="{'owned': child.owner == current_user}">
                                    <td><span class="child-sort-handle"><i class="fa fa-th mr-3"></i></span></td>
                                    <td>
                                        <div class="c_checkbox" v-if="child.pending_completed !== undefined">
                                            <label>
                                                <input type="checkbox" v-if="child.pending_completed == 0"
                                                       v-model="child.pending_completed"
                                                       @change="toggleChildCompletion(child)">
                                                <input type="checkbox" v-else checked v-model="child.pending_completed"
                                                       @change="toggleChildCompletion(child)">
                                                <span class="icon"><i class="fa fa-check"></i></span>
                                            </label>
                                        </div>

                                        <div class="c_checkbox" v-else>
                                            <label>
                                                <input type="checkbox" v-if="child.pendin_complete == 0"
                                                       v-model="child.pendin_complete"
                                                       @change="toggleChildCompletion(child)">
                                                <input type="checkbox" v-else checked v-model="child.pendin_complete"
                                                       @change="toggleChildCompletion(child)">
                                                <span class="icon"><i class="fa fa-check"></i></span>
                                            </label>
                                        </div>
                                    </td>
                                    <td class="minwidth2"
                                        v-bind:class="{'strike-through' : child.pending_completed == 1 || child.pendin_complete == 1}">
                    <span v-if="child.title !== undefined"><input class="form-control" v-model="child.title"
                                                                  @blur="updateSubtask($event, child)"></span>
                                        <span v-else><input class="form-control" v-model="child.part_name"
                                                            @blur="updateSubtask($event, child)"></span>

                                        <div class="my-2 child_note" v-if="child.note"
                                             v-html="wrapURL(child.note, true)"></div>

                                        <div class="mb-2 subtask_notes" style="display:none">
                                            <note-editor v-model="child.note"
                                                         @blur="updateChildNote($event,  child)"></note-editor>
                                        </div>
                                    </td>
                                    <td class="minwidth">
                                        <ul class="ml-4 d-flex inline-list p-0">
                                            <li class="mr-2">
                                                <button class="btn btn-sm" title="Add/Edit Notes"
                                                        @click="addSubtaskNotes($event)">
                                                    <i class="fa fa-pen-square"></i>
                                                </button>
                                            </li>
                                            <li class="d-inline-block">
                                                <button class="btn btn-sm" title="" @click="deleteSubTask(child)">
                                                    <i class="fa fa-trash-alt"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </td>
                                    <td class="minwidth">
                                        <div class="input-group input-group-sm">
                                            <select class="form-control ml-auto input-sm" v-model="child.owner"
                                                    @change="changeChildOwner(child)">
                                                <option v-for="user in users" :value="user.id" v-bind:key="user.id">
                                                    {{ user.nicename }}
                                                </option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                    </div>
                </div>

                <div class="card-footer d-flex" v-if="new_sub_task || !hide && subtasks && subtasks.length > 0">
                    <div class="new_sub_task w-100" v-show="new_sub_task || !hide && subtasks && subtasks.length > 0">
                        <div>
                            <div class="input-group input-group-sm">
                                <input placeholder="Add Subtask" class="form-control" @blur="addSubtask"
                                       v-model="subtask_title"
                                       v-on:keyup.enter="addSubtask">
                            </div>
                        </div>
                    </div>

                    <div class="buttons" v-if="0==1">
                        <ul class="inline-list">
                            <li>
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top"
                                        title="Add Subtask"
                                        @click="new_sub_task = !new_sub_task"><i class="fa fa-list"></i></button>
                            </li>
                            <li>
                                <button class="btn btn-sm ld-over text-info" @click="copyTasks($event)"
                                        data-toggle="tooltip"
                                        data-placement="top" title="Copy Tasks">
                                    <i class="fa fa-copy"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>
                            <li v-if="s_task.job_id">
                                <a target="_blank"
                                   :href="'http://darkside-developments.com/jobs/jobsheet1.php?x='+s_task.job_id+'#jobCard'"
                                   class="btn btn-sm" data-toggle="tooltip" data-placement="top"
                                   title="Go to jobcard"><i
                                    class="fa fa-external-link-alt"></i></a>
                            </li>
                            <li>
                                <button class="btn btn-sm text-success" data-toggle="tooltip" data-placement="top"
                                        title="Edit/Add Note"
                                        @click="editTask"><i class="fa fa-pen-square"></i></button>
                            </li>
                            <li>
                                <button class="btn btn-sm text-danger ld-over" data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete Task" @click="deleteTask">
                                    <i class="fa fa-trash-alt"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>
                            <li v-if="subtasks.length > 0">
                                <button class="btn btn-sm" data-toggle="tooltip" data-placement="top"
                                        title="Uncomplete Subtasks"
                                        @click="uncompleteTasks"><i class="fas fa-redo"></i></button>
                            </li>
                            <li class="all-time-spent"><span class="timespent_modal" data-toggle="modal"
                                                             :data-target="'#tasktimes-'+s_task.id"><i
                                class="ml-4 mr-1 far fa-clock"></i> {{ msToTime(totalTime) }}</span></li>
                        </ul>
                    </div>

                    <div class="buttons ml-auto" v-if="0==1">
                        <ul class="inline-list">
                            <li v-if="foundTaskTime">
                                <button class="btn btn-sm ld-over" title="" @click="pauseTime($event,s_task.id)">
                                    <i class="fa fa-pause"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>
                            <li v-else>
                                <button class="btn btn-sm ld-over" title="" @click="playTime($event,s_task.id)">
                                    <i class="fa fa-play"></i>
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </li>

                            <li v-if="foundTaskTime">
                                <p class="mb-0">
                                    {{ timeSpent }}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" :id="'tasktimes-'+s_task.id" tabindex="-1" role="dialog"
             :aria-labelledby="'tasktimesLabel-'+s_task.id" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" :id="'tasktimesLabel-'+s_task.id">Task Times</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <th>Start</th>
                                <th>Pause</th>
                                <th>Spent</th>
                                <th>User</th>
                                <th>Task List</th>
                                </thead>
                                <tbody>
                                <tr v-for="time in s_task.times" v-bind:key="time.id">
                                    <td>{{ time.start_time | date }}</td>
                                    <td>{{ time.pause_time | date }}</td>
                                    <td>{{ totalTaskTime(time.start_time, time.pause_time) }}</td>
                                    <td>{{ time.user | username(users) }}</td>
                                    <td>{{ time.task_list | username(users) }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <span class="mr-auto"><i class="ml-4 mr-1 far fa-clock"></i> {{ msToTime(totalTime) }}</span>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var timer = 0;
var mouseleft = false;
$(document).mouseleave(function (e) {
    mouseleft = true;
});
$(document).mouseenter(function (e) {
    mouseleft = false;
});

import moment from 'moment';

export default {
    props: ['task', 'current_user', 'deleted'],
    beforeMount() {
        this.s_task = this.task;
        this.s_task.title = this.replace(this.s_task.title);
        this.s_task.note = this.replace(this.s_task.note);
        if (this.s_task.subtasks) {
            this.subtasks = this.s_task.subtasks.concat(this.s_task.jobparts);
        }
        this.subtasks.sort(order);
        //this.subtasks = _.sortBy(this.subtasks, 'order');
        this.subtasks.forEach(st => {
            st.note = this.replace(st.note);
            st.part_name = this.replace(st.part_name);
            st.title = this.replace(st.title);
            st.hidden = false;
        })
    },
    mounted() {
        if (localStorage.lastOpenedTask == this.s_task.id) {
            this.hide = false;
        }
    },
    updated: function () {
        var self = this;
    },
    created: function () {
    },
    data: function () {
        return {
            dragOptions: {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                dragClass: "drag",
                forceFallback: true,
                handle: ".child-sort-handle"
                // fallbackOnBody: true,
            },
            playing: false,
            hide: true,
            hideNote: true,
            timeSpent: 0,
            s_task: null,
            stoppedTask: false,
            editor: null,
            edit_title: false,
            subtask_title: "",
            new_sub_task: false,
            add_sub_task_notes: false,
            subtasks: [],
            totalTime: 0,
            foundTaskTime: false,
            showOwned: false,
        }
    },
    watch: {
        task: function (newVal) {
            this.s_task = newVal;
            this.subtasks = newVal.subtasks.concat(newVal.jobparts);
            this.subtasks.sort(order);
        },
        s_task: function (newVal) {
            var self = this;
            if (newVal.subtasks) {
                this.s_task.subtasks = newVal.subtasks.sort(compare)
            }

            if (newVal.times) {
                this.s_task.times = newVal.times.sort(function (a, b) {
                    return new Date(b.start_time.replace(/\s/, 'T')) - new Date(a.start_time.replace(/\s/, 'T'));
                });

                this.foundTaskTime = this.s_task.times.find(function (element) {
                    return element.user == self.user_id && element.pause_time == null;
                });
                if (this.foundTaskTime) {
                    this.playing = true;
                    this.getTimeSpent(this.foundTaskTime.start_time, this.foundTaskTime.pause_time, moment());
                } else {
                    this.playing = false;
                }
            } else {
                this.playing = false;
            }


            this.totalTime = 0;

            var total = 0;
            if (this.s_task !== null && this.s_task.times !== null && this.s_task !== undefined && this.s_task.times !== undefined) {
                this.s_task.times.forEach(element => {
                    var start = new Date(element.start_time);
                    var end;
                    if (element.pause_time == null) {
                        end = new Date();
                    } else {
                        end = new Date(element.pause_time);
                    }
                    total += end - start;
                });
            }
            this.totalTime = total;

            if (this.showOwned) {
                this.reloadOwnedTasks();
            }

            this.s_task.note = this.escapeHtml(this.s_task.note)
        }
    },
    methods: {
        escapeHtml(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            var newVal = this.replace(_.unescape(textArea.value));
            return newVal;
            //return text;
        },
        updateTask: function () {
            var self = this;
            axios.get(api_url + '/api/task/' + self.s_task.id).then(response => {
                self.s_task = response.data;
                self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                self.subtasks.sort(order);
                if (response.data.times && response.data.times.pause_time == null) {
                    self.playing = true;
                } else {
                    self.playing = false;
                }
                self.$forceUpdate();
            }).catch(e => {
                this.$toasted.error('Failed to get task!', {icon: 'exclamation-triangle'});
            });
        },
        getTimeSpent: function (start, end, now) {
            var self = this;
            var spentTime = 0;
            var timer = setInterval(() => {
                if (self.playing == true) {
                    var endT = null;
                    var startT = null;
                    if (end == null) {
                        endT = moment().tz("Europe/London");
                    } else {
                        endT = moment(end.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
                    }
                    if (start == null) {
                        startT = moment().tz("Europe/London");
                    } else {
                        startT = moment(start.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
                    }

                    var a = null;
                    var b = null;
                    if (end == null) {
                        a = endT;
                        b = startT;
                        self.timeSpent = convertTimeHHMMSS(a.diff(b) / 1000);
                    } else {
                        a = endT;
                        b = startT;
                        self.timeSpent = convertTimeHHMMSS(a.diff(b) / 1000);
                    }
                } else {
                    clearInterval(timer);
                }
            }, 500);
        },
        msToTime: function (ms) {
            function pad(n, amount = 0) {
                if (n < 10) {
                    return 0 + n.toFixed(amount);
                } else {
                    return n.toFixed(amount);
                }
            }

            var seconds = ms / 1000;
            // 2- Extract hours:
            var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
            seconds = seconds % 3600; // seconds remaining after extracting hours
            var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
            seconds = seconds % 60;
            return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds, 0);
        },
        smokeTime(event, id) {
            var self = this;
            this.toggleLoader(event, 'start');
            const formData = new FormData();
            formData.append('id', id);
            formData.append('user', this.user_id);
            formData.append('taskList', this.user_id);
            formData.append('reason', "Cig Break");
            axios.post(api_url + '/api/task/pause', formData, {
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded',
                }
            }).then(response => {
                self.s_task = response.data;
                self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                self.subtasks.sort(order);
                self.playing = false;
                self.timeSpent = 0;
                self.toggleLoader(event, 'stop');
            })
                .catch(e => {
                    self.toggleLoader(event, 'stop');
                    this.$toasted.error('Failed to pause task!', {icon: 'exclamation-triangle'});
                })
        },
        pauseTime: function (event, id) {
            var self = this;
            this.toggleLoader(event, 'start');
            const formData = new FormData();
            formData.append('id', id);
            formData.append('user', this.user_id);
            formData.append('taskList', this.user_id);
            axios.post(api_url + '/api/task/pause', formData, {
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded',
                }
            }).then(response => {
                self.s_task = response.data;
                self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                self.subtasks.sort(order);
                self.playing = false;
                self.timeSpent = 0;
                self.toggleLoader(event, 'stop');
            })
                .catch(e => {
                    self.toggleLoader(event, 'stop');
                    this.$toasted.error('Failed to pause task!', {icon: 'exclamation-triangle'});
                })
        },
        playTime: function (event, id) {
            var self = this;
            if (self.playing == false) {
                this.toggleLoader(event, 'start');
                const formData = new FormData();
                formData.append('id', id);
                formData.append('user', this.user_id);
                formData.append('taskList', this.user_id);
                axios.post(api_url + '/api/task/play', formData, {
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded',
                    }
                }).then(response => {
                    self.$parent.$parent.$parent.onBreak = false;
                    self.playing = true;
                    self.s_task = response.data;
                    self.getTimeSpent(self.s_task.times[0].start_time, self.s_task.times[0].pause_time);
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort(order);
                    self.$parent.$parent.$parent.getTasks();
                    self.toggleLoader(event, 'stop');
                })
                    .catch(e => {
                        console.log(e)
                        self.toggleLoader(event, 'stop');
                        this.$toasted.error('Failed to start task!', {icon: 'exclamation-triangle'});
                    })
            }
        },
        sortUpdate: function (event) {
            var self = this;
            axios.post(api_url + '/api/task/subtasks/reorder', {
                current_user: self.current_user,
                sort: self.subtasks
            }).then(response => {
                self.updateTask();
            })
                .catch(e => {
                    this.$toasted.error('Failed to update subtask order!', {icon: 'exclamation-triangle'});
                })
        },
        toggleCompletion: function () {
            var self = this;
            axios.post(api_url + '/api/task/status', {
                task: self.task
            }).then(response => {
                self.playing = false;
            })
                .catch(e => {
                    this.$toasted.error('Failed to change status!', {icon: 'exclamation-triangle'});
                })
        },
        toggleChildCompletion: function (child) {
            var self = this;
            child.task_id = self.s_task.id;
            axios.post(api_url + '/api/task/subtask/update/completion', {
                subtask: child
            }).then(response => {
                self.playing = false;
                //self.s_task = response.data;
                //self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                //self.subtasks.sort( order );
            })
                .catch(e => {
                    this.$toasted.error('Failed to change subtask status!', {icon: 'exclamation-triangle'});
                })
        },
        editTask: function () {
            this.hide = false;
            this.hideNote = !this.hideNote;
            if (this.hideNote == false) {
                setTimeout(() => {
                    $('.ProseMirror').attr("spellcheck", true)
                }, 1000);
            }
            //this.$refs.text_editor.edit = !this.$refs.text_editor.edit;
        },
        updateNote: function (e) {
            if (mouseleft == true) {
                var self = this;
                axios.post(api_url + '/api/task/update/note', {
                    job_id: self.s_task.job_id,
                    note: self.s_task.note,
                    id: self.s_task.id
                }).then(response => {
                    if (response.data == "Success") {
                        //self.hide = true;
                        //self.hideNote = true;
                        self.s_task.note = self.replace(self.s_task.note);
                    } else {
                        this.$toasted.error('Failed to update!', {icon: 'exclamation-triangle'});
                    }
                })
                    .catch(e => {
                        this.$toasted.error('Failed to update!', {icon: 'exclamation-triangle'});
                    })
            } else {
                var self = this;
                axios.post(api_url + '/api/task/update/note', {
                    job_id: self.s_task.job_id,
                    note: self.s_task.note,
                    id: self.s_task.id
                }).then(response => {
                    if (response.data == "Success") {
                        //self.hide = true;
                        self.hideNote = true;
                        self.s_task.note = self.replace(self.s_task.note);
                    } else {
                        this.$toasted.error('Failed to update!', {icon: 'exclamation-triangle'});
                    }
                })
                    .catch(e => {
                        this.$toasted.error('Failed to update!', {icon: 'exclamation-triangle'});
                    })
            }
        },
        updateChildNote: function (event, child) {
            /*var self = this;
            child.task_id = this.s_task.id;
            axios.post(api_url + '/api/task/subtask/update/note',{
                subtask: child
            }).then(response => {
                self.s_task = response.data;
                self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                self.subtasks.sort( order );
                var tr = $(event.target).closest('tr');
                var note_field = tr.find('.subtask_notes');
                note_field.hide();
                $('.subtask_notes').each(function(){
                    $(this).hide();
                })
            })
            .catch(e => {
                this.$toasted.error( 'Failed to update subtask note!', {icon:'exclamation-triangle'});
            })*/
            if (mouseleft == true) {
                var self = this;
                child.task_id = this.s_task.id;
                axios.post(api_url + '/api/task/subtask/update/note', {
                    subtask: child
                }).then(response => {
                })
                    .catch(e => {
                    })
            } else {
                var self = this;
                child.task_id = this.s_task.id;
                axios.post(api_url + '/api/task/subtask/update/note', {
                    subtask: child
                }).then(response => {
                    self.s_task = response.data;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort(order);
                    var tr = $(event.target).closest('tr');

                    var note_field = tr.find('.subtask_notes');
                    note_field.hide();
                    $('.subtask_notes').each(function () {
                        $(this).hide();
                    })

                    var child_note = tr.find('.child_note');
                    child_note.show();
                    $('.child_note').each(function () {
                        $(this).show();
                    })
                })
                    .catch(e => {
                        this.$toasted.error('Failed to update subtask note!', {icon: 'exclamation-triangle'});
                    })
            }
        },
        editTitle(id) {
            this.edit_title = true;
            var elem = $('#title-edit-' + id);
            setTimeout(function () {
                elem.focus();
            }, 500);
        },
        editTitleTap(id) {
            return ((direction, event) => {
                this.edit_title = true;
                var elem = $('#title-edit-' + id);
                setTimeout(function () {
                    elem.focus();
                }, 500);
            })
        },
        updateTitle: function () {
            var self = this;
            this.edit_title = false;
            axios.post(api_url + '/api/task/update/title', {
                title: self.s_task.title,
                id: self.s_task.id
            }).then(response => {
            })
                .catch(e => {
                    this.$toasted.error('Failed to update title!', {icon: 'exclamation-triangle'});
                })
        },
        addSubtask: function () {
            var self = this;
            if (self.subtask_title.length > 0) {
                axios.post(api_url + '/api/task/new/subtask', {
                    owner: self.current_user,
                    title: self.subtask_title,
                    id: self.s_task.id
                }).then(response => {
                    self.subtask_title = "";
                    self.s_task = response.data;
                    self.s_task.subtasks = response.data.subtasks;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort(order);
                    self.subtask_title = "";
                    self.new_sub_task = false;
                    self.hide = false;
                    self.$forceUpdate();
                })
                    .catch(e => {
                        this.$toasted.error('Failed to add subtask!', {icon: 'exclamation-triangle'});
                    })
            }
        },
        addJobSubtask: function () {
            var tasklist_owner = this.current_user;
            var self = this;
            if (self.subtask_title.length > 0) {
                axios.post(api_url + '/api/task/new/jobtask', {
                    job_id: self.s_task.job_id,
                    title: self.subtask_title,
                    owner: 79
                }).then(response => {
                    self.subtask_title = "";
                    self.$parent.$parent.$parent.getTasks(tasklist_owner);
                    self.hide = false;
                })
                    .catch(e => {
                        this.$toasted.error('Failed to add subtask!', {icon: 'exclamation-triangle'});
                    })
            }
        },
        addSubtaskNotes: function (e) {
            var tr = $(e.target).closest('tr');
            var note_field = tr.find('.subtask_notes');
            note_field.show();

            var child_note_container = tr.find('.ProseMirror');
            $(child_note_container).on('DOMSubtreeModified', function () {
                var child_note1 = tr.find('.child_note');
                child_note1.hide();
            })
            var child_note = tr.find('.child_note');
            child_note.hide();
        },
        deleteTask: function (event) {
            var tasklist_owner = this.current_user;
            var conf = confirm('Are you sure you want to delete the task?');
            if (conf) {
                var self = this;
                self.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/delete', {
                    id: self.s_task.id
                }).then(response => {
                    if (response.data == "Success") {
                        self.$parent.$parent.$parent.getTasks(tasklist_owner);
                        self.toggleLoader(event, 'stop');
                    } else {
                        self.toggleLoader(event, 'stop');
                        this.$toasted.error('Failed to delete task!', {icon: 'exclamation-triangle'});
                    }
                })
                    .catch(e => {
                        console.log(e)
                        this.$toasted.error('Failed to delete task!', {icon: 'exclamation-triangle'});
                        self.toggleLoader(event, 'stop');
                    })
            }
        },
        deleteSubTask: function (child) {
            var conf = confirm('Are you sure you want to delete the subtask?');
            if (conf) {
                var self = this;
                child.task_id = this.s_task.id;
                axios.post(api_url + '/api/task/delete/subtask', {
                    subtask: child
                }).then(response => {
                    self.s_task = response.data;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort(order);
                })
                    .catch(e => {
                        this.$toasted.error('Failed to delete subtask!', {icon: 'exclamation-triangle'});
                    })
            }
        },
        changeChildOwner: function (child) {
            var self = this;
            child.task_id = this.s_task.id;
            axios.post(api_url + '/api/task/subtask/update/owner', {
                subtask: child
            }).then(response => {
                self.s_task = response.data;
                self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                self.subtasks.sort(order);
            })
                .catch(e => {
                    this.$toasted.error('Failed to update owner!', {icon: 'exclamation-triangle'});
                })
        },
        changeOwner: function () {
            var self = this;
            axios.post(api_url + '/api/task/update/owner', {
                task: this.s_task
            }).then(response => {
                self.$parent.$parent.$parent.getTasks();
            })
                .catch(e => {
                    this.$toasted.error('Failed to update owner!', {icon: 'exclamation-triangle'});
                })
        },
        unescape: function (val) {
            if (val == null && val !== undefined && val !== "") {
                var el = document.createElement('div');
                return val.replace(/\&[#0-9a-z]+;/gi, function (enc) {
                    el.innerHTML = enc;
                    return el.innerText
                });
            } else {
                return val;
            }
        },
        wrapURL: function (val, new_window) {
            if (val !== null && val !== "" && val !== undefined) {
                var url_pattern = /((http?|https?|ftps?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/g;

                setTimeout(() => {
                    $('a[href^="http://"]').attr('target', '_blank');
                    $('a[href^="https://"]').attr('target', '_blank');
                }, 500);

                return val.replace(url_pattern, '<a target="_blank" href="$1">$1</a>');
            } else {
                return val;
            }
        },
        copyTasks: function (event) {
            this.toggleLoader(event, 'start');
            var rowtext = "";
            this.subtasks.forEach(element => {
                if (element.title) {
                    rowtext += element.title + "\r\n";
                } else {
                    rowtext += element.part_name + "\r\n";
                }
            });
            this.copyToClipboard(rowtext);
            this.toggleLoader(event, 'stop');
        },
        toggleLoader: function (event, status) {
            var button = event.target.closest('button');
            if (status == "stop") {
                $(button).removeClass('running');
            } else if (status == "start") {
                $(button).addClass('running');
            }
        },
        copyToClipboard: function (element) {
            $('#copyarea').val(element);
            $('#copyarea').select();
            document.execCommand("copy");
            //$('#copyarea').val('');
        },
        totalTaskTime: function (start, pause) {
            var start = new Date(start.replace(/\s/, 'T')).getTime();
            var end;
            if (pause == null) {
                end = new Date().getTime();
            } else {
                end = new Date(pause.replace(/\s/, 'T')).getTime();
            }
            return this.msToTime(end - start);
        },
        uncompleteTasks: function () {
            var conf = confirm('Are you sure you want to uncomplete all subtasks?');
            if (conf) {
                var self = this;
                var id = this.s_task.id;
                axios.post(api_url + '/api/task/subtask/reset', {
                    id: id
                }).then(response => {
                    self.s_task = response.data;
                    self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                    self.subtasks.sort(order);
                })
                    .catch(e => {
                        this.$toasted.error('Failed to uncomplete all subtasks!', {icon: 'exclamation-triangle'});
                    })
            }
        },
        toggleSubtasks: function () {
            this.hide = !this.hide;
            if (this.hide == true) {
                localStorage.lastOpenedTask = null;
            } else {
                localStorage.lastOpenedTask = this.s_task.id;
            }
        },
        updateSubtask: function (event, child) {
            var value = event.target.value;
            var id = child.id;
            var self = this;
            axios.post(api_url + '/api/task/subtask/update/title', {
                id: self.s_task.id,
                subtask: child,
                value: value
            }).then(response => {
                self.s_task = response.data;
                self.subtasks = self.s_task.subtasks.concat(self.s_task.jobparts);
                self.subtasks.sort(order);
            })
                .catch(e => {
                    this.$toasted.error('Failed to update subtask!', {icon: 'exclamation-triangle'});
                })
        },
        showOwnedTasks: function (task) {
            var self = this;
            if (self.showOwned == false) {
                self.subtasks.forEach(st => {
                    if (st.owner == self.current_user) {
                        st.hidden = false;
                    } else {
                        st.hidden = true;
                    }
                });
                this.showOwned = true;
            } else {
                self.subtasks.forEach(st => {
                    st.hidden = false;
                });
                this.showOwned = false;
            }
            self.$forceUpdate();
        },
        reloadOwnedTasks: function (task) {
            var self = this;
            self.subtasks.forEach(st => {
                if (st.owner == self.current_user) {
                    st.hidden = false;
                } else {
                    st.hidden = true;
                }
            });
            this.showOwned = true;
            self.$forceUpdate();
        },
        replace(s) {
            if (s !== "" && s !== null && s !== undefined) {
                s = s.replace(/Â\s/g, '');
                s = s.replace(/^\s*/g, '');
                s = s.replace(/<p><br><\/p>/g, '');
                s = _.unescape(s);
                $('a[href^="http://"]').attr('target', '_blank');
                $('a[href^="https://"]').attr('target', '_blank');
                return this.wrapURL(s);
            } else {
                return this.wrapURL(s);
            }
        }
    },
    filters: {
        username: ((val, users) => {
            var obj = users.find(item => item.id == val);
            if (obj !== undefined) {
                return obj.nicename;
            } else {
                return val;
            }
        }),
        date: ((val) => {
            return moment(val).format("DD-MM-YYYY hh:mm:ss");
        })
    },
    computed: {
        username() {
            if (this.$store.state.auth.user) {
                return this.$store.state.auth.user.nicename;
            } else {
                return null
            }
        },
        user_id() {
            return this.current_user
        },
        users() {
            return JSON.parse(localStorage.getItem('staff') || []);
        }
    },
}

window.convertTimeHHMMSS = function convertTimeHHMMSS(val) {
    let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
    return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
};

function compare(a, b) {
    const orderA = a.order;
    const orderB = b.order;

    let comparison = 0;
    if (orderA > orderB) {
        comparison = 1;
    } else if (orderA < orderB) {
        comparison = -1;
    }
    return comparison;
}

function order(a, b) {
    var order_a;
    var order_b;
    if (a.order == undefined) {
        order_a = a.sort_order
    } else {
        order_a = a.order;
    }

    if (b.order == undefined) {
        order_b = b.sort_order
    } else {
        order_b = b.order;
    }

    if (order_a < order_b) {
        return -1;
    }
    if (order_a > order_b) {
        return 1;
    }
    return 0;
}

</script>

<template>
    <div class="pb-5 position-relative mvt-wrap" style="height:100%">
        <div class="">
            <div class="row mb-1">
                <div class="col">
                    <select class="form-control w-auto d-inline-block" v-model="task_user" @change="changeUser">
                        <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
                    </select>
                    <a class="d-inline-block ml-2" :href="'/'+task_user" target="_blank"><i class="fa fa-external-link-alt"></i></a>
                </div>
                <div class="col-xs-5">
                    <button class="btn btn-sm btn-danger mt-1 mr-2" @click="updateTasks" v-if="hasNewTasks"><i class="fas fa-exclamation-triangle"></i><span v-if="newTasksCount > 0">{{newTasksCount}}</span></button>
                    <button class="btn btn-sm ml-auto green ld-over mt-1 float-right mr-3" @click="removeCompleted($event)">
                        Remove Completed
                        <div class="ld ld-spinner ld-spin"></div>
                    </button>
                </div>
            </div>
        </div>
        
        <div class="wrap-views" style="height: 100%; padding-bottom:20px">
            <div class="row mb-2">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header py-1">
                            <div class="new-task-wrap">
                                <input type="text" class="header-input form-control" placeholder="Add Task..." :value="new_task" @input="e => new_task = e.target.value" @keydown.tab="addTask($event)">
                                <button class="btn btn-sm ld-over" v-if="new_task.length > 0" @click="addTask($event)">
                                    Add Task
                                    <div class="ld ld-spinner ld-spin"></div>
                                </button>
                            </div>
                            <div class="custom-control custom-switch float-right mt-2">
                                <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="task_top">
                                <label class="custom-control-label" for="customSwitch1" v-if="task_top">Top</label>
                                <label class="custom-control-label" for="customSwitch1" v-else>Bottom</label>
                            </div>
                            <div class="float-right mr-4 mt-2" @click="toggleNewTask = !toggleNewTask"><i class="fa fa-caret-down"></i></div>
                        </div>
                        <div class="card-body" v-if="toggleNewTask">
                            <div class="form-group">
                                <label>Notes</label>
                                <wysiwyg :content="wysiwyg_content" ref="new_task_desc"></wysiwyg>
                            </div>
                            <div class="form-group mt-2">
                                <label>Priority</label>
                                <select class="form-control" v-model="priority">
                                    <option>High</option>
                                    <option selected>Normal</option>
                                    <option>Low</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--v-sortable="{handle: '.sort-handle', onUpdate:onUpdate}"-->
            <draggable id="task_list" v-model="tasks" v-bind="dragOptions" @end="onUpdate" tag="ul" ref="drag_list">
                <transition-group ref="transition_list">
                <li class="mb-1 ghost" v-for="task in tasks" v-bind:key="task.id">
                    <multiview-table-task-view :auth="auth" :task="task" :allusers="users" :current_user="task_user" :original_owner="current_user" ref="user_task"></multiview-table-task-view>
                </li>
                </transition-group>
            </draggable>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['users','current_user','auth'],
        name: "app",
        mounted() {
            this.task_user = this.current_user;
            this.getTasks();
            setTimeout(() => {
                this.getNewTasks();
            }, 5000);
        },
        data: function () {
            return {
                dragOptions: {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost",
                    dragClass: "drag",
                    forceFallback: true,
                    handle: ".sort-handle"
                },
                tempTasks: [],
                tasks: [],
                sortOrder: [],
                toggleNewTask: false,
                new_task: "",
                wysiwyg_content: "",
                task_top: true,
                isLoading: true,
                fullPage: true,
                priority: "Normal",
                lastChecked: moment().format('YYYY-MM-DD HH:mm:ss'),
                task_user: null,
                newTasksCount: 0,
                newTasks: [],
                hasNewTasks: false,
            }
        },
        watch: {
        },
        methods: {
            getTasks: function(id){
                var self = this;
                axios.get(api_url + '/api/tasks/'+self.task_user).then(response => {
                    if(Array.isArray(response.data)){
                        self.tasks = response.data;
                        self.isLoading = false;
                        self.$refs.user_task.$forceUpdate();
                    } else {
                        this.$toasted.error( 'Failed to get tasks!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                })
            },
            updateTasks(){
                var conf = confirm('This will refresh your tasklist, any unsaved notes will be refreshed. If you are sure you want to refresh the list then click "OK"');
                if(conf){
                    this.tasks = this.newTasks;
                    this.newTasks = [];
                    this.newTasksCount = 0;
                    this.hasNewTasks = false;
                }
            },
            onUpdate: function (evt, originalEvent) {
                var self = this;
                var newOrder = [];
                this.tasks.forEach(element => {
                    newOrder.push(element.id);
                });
                self.sortOrder = newOrder;
                
                axios.post(api_url + '/api/tasks/reorder',{
                    current_user: self.task_user,
                    sort: self.sortOrder
                }).then(response => {
                    
                })
                .catch(e => {
                })
            },
            addTask: function(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                var title = this.new_task;
                var desc = this.wysiwyg_content;
                var task_top = this.task_top;
                var task_pos = 'Top';
                if(this.wysiwyg_content == "<p></p>") {
                    desc = "";
                }

                if(task_top == true) {
                    task_pos = 'Top';
                } else {
                    task_pos = 'Bottom';
                }

                if(self.toggleNewTask == false) {
                    desc = "";
                }

                axios.post(api_url + '/api/task/new',{
                    current_user: self.task_user,
                    title: title,
                    note: desc,
                    position: task_pos,
                    priority: self.priority
                }).then(response => {
                    if(response.data == "Success"){
                        self.new_task = "";
                        self.wysiwyg_content = "";
                        self.toggleNewTask = false;
                        self.getTasks();
                        self.toggleLoader(event, 'stop');
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to add task!', {icon:'exclamation-triangle'});
                });
            },
            removeCompleted: function(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/remove/completed',{
                    current_user: self.task_user,
                }).then(response => {
                    if(response.data == "Success"){
                        self.getTasks();
                        self.toggleLoader(event, 'stop');
                    } else {
                        this.$toasted.error( 'Failed to remove completed tasks!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to remove completed tasks!', {icon:'exclamation-triangle'});
                })
            },
            toggleLoader: function (event, status) {
                var button = event.target.closest('button');
                if(status == "stop") {
                    $(button).removeClass('running');
                } else if(status == "start") {
                    $(button).addClass('running');
                }
            },
            getNewTasks: function (){
                var self = this;
                setInterval(() => {
                    var checkTime = this.lastChecked;
                    axios.post(api_url + '/api/tasks/'+self.task_user+'/latest',{
                        time: checkTime
                    }).then(response => {
                        self.lastChecked = moment().format('YYYY-MM-DD HH:mm:ss');
                        response.data.forEach(resp => {
                            //self.tasks.splice(resp.order, 0, resp)//self.tasks.push(resp)
                            self.tasks.push(resp)
                        });
                    })
                    .catch(e => {
                        this.$toasted.error( 'Failed to get tasks!', {icon:'exclamation-triangle'});
                    })
                }, 180000);
            },
            changeUser: function() {
                this.getTasks();
            },
            getNewTasks: function (){
                var self = this;
                setInterval(() => {
                    var checkTime = this.lastChecked;
                    axios.post(api_url + '/api/tasks/'+self.current_user+'/latest',{
                        time: checkTime
                    }).then(response => {
                        self.lastChecked = moment().format('YYYY-MM-DD HH:mm:ss');
                        if(response.data !== "") {
                            var newtask = false;

                            if(!this.arraysMatch(response.data, this.tasks)){
                                this.hasNewTasks = true;
                                this.newTasksCount = response.data.length - this.tasks.length;
                                this.newTasks = response.data;
                            }
                        }
                    })
                    .catch(e => {
                        this.$toasted.error( 'Failed to get tasks!', {icon:'exclamation-triangle'});
                    })
                }, 30000);
            },
            arraysMatch(arr1, arr2) {
                if (arr1.length !== arr2.length) return false;
                for (var i = 0; i < arr1.length; i++) {
                    if (arr1[i].subtasks.length !== arr2[i].subtasks.length) return false;
                }
                return true;

            }
        },
        filters: {
            username: ((val, users) => {
                var obj = users.find(item=>item.id==val);
                if(obj !== undefined) {
                    return obj.nicename;
                } else {
                    return val;
                }
            }),
            date: ((val) => {
                return moment(val).format("DD-MM-YYYY hh:mm:ss"); 
            })
        }
    }
</script>
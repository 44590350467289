<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-12 alltasks">
                <table-tasks ref="tasks_list"></table-tasks>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
}
</script>

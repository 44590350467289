import Spinner from './spinner.vue'
import Dots from './dots.vue'
import Bars from './bars.vue'
import LineBar from './line.vue'

export default {
  Spinner,
  Dots,
  Bars,
  LineBar,
}

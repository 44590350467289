import Vue from "vue";
const state = {
	status: "",
	user: {},
	staff: [],
};

const getters = {
	getProfile: (state) => state.user,
	isProfileLoaded: (state) => !!state.profile.name,
};

const actions = {
	userRequest: ({ commit, dispatch }) => {
		return new Promise((resolve, reject) => {
			commit("userRequest");
			axios
				.get(api_url + "/api/me")
				.then((resp) => {
					localStorage.clear();
                    commit("userSuccess", resp.data);
                    
                    axios.get(api_url + "/api/staff").then((resp2) => {
                        commit("staffSuccess", resp2.data);
                        resolve();
                    }).catch((err) => {
                        commit("staffError");
                        dispatch("authLogout");
                        reject(err);
                    });
				})
				.catch((err) => {
					commit("userError");
					// if resp is unauthorized, logout, to
					dispatch("authLogout");
					reject(err);
				});
		});
	},
};

const mutations = {
	userRequest: (state) => {
        state.status = "loading";
        state.user = {};
	},
	userSuccess: (state, resp) => {
		state.status = "success";
		Vue.set(state, "user", resp);
	},
	userError: (state) => {
        state.status = "error";
        state.user = {};
    },
    staffSuccess: (state, resp) => {
		Vue.set(state, "staff", resp);
	},
	staffError: (state) => {
        state.staff = [];
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};

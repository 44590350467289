<template>
    <div class="pb-5 position-relative">
        <loading :active.sync="isLoading" 
        :can-cancel="false"
        loader="LineBar"
        :is-full-page="fullPage"></loading>
        
        <div v-if="$router.currentRoute.name !== 'SingleTask' && !$router.currentRoute.query.frompaint">
            <h3 class="mb-3 d-inline-block"><i class="fas fa-tasks"></i> {{username}} ({{tasks.length}})</h3>
            <div class="mb-3 d-inline-block">
                <router-link :to="'/tasks/completed/'+current_user" class="ml-4 btn btn-sm header-btns" v-show="!onBreak">Go to Completed</router-link>
                <router-link :to="'/tasks/deleted/'+current_user" class="ml-2 btn btn-sm header-btns" v-show="!onBreak">Go to Deleted</router-link>
                <button class="btn btn-sm ml-2 mr-2 green ld-over" @click="removeCompleted($event)" v-show="!onBreak">
                    Remove Completed<div class="ld ld-spinner ld-spin"></div>
                </button>
                <a href="/task/new" class="btn btn-sm mr-2" v-show="!onBreak">Add Task</a>
                <switch-list class="switch-list mr-2" :allusers="users" v-if="!onBreak"></switch-list>

                <button class="btn btn-sm" v-if="newTasksCount == 0" disabled v-show="!onBreak">No new tasks</button>
                <button class="btn btn-sm btn-danger" @click="updateTasks" v-else-if="newTasksCount == 1"><i class="fas fa-exclamation-triangle"></i> {{newTasksCount}} new task!</button>
                <button class="btn btn-sm btn-danger" @click="updateTasks" v-else><i class="fas fa-exclamation-triangle"></i> {{newTasksCount}} new tasks!</button>
                <button class="btn btn-sm ld-over ml-3" title="Break" @click="fagBreak($event)" v-if="!onBreak">
                    <i class="fas fa-coffee mr-2"></i> <b>BREAK</b>
                    <div class="ld ld-spinner ld-spin"></div>
                </button>
                <button class="btn btn-sm ld-over ml-3 btn-danger stopBreakBtn" v-else @click="fagBreakStop">
                    <b>STOP BREAK</b>
                </button>
            </div>
        </div>

        <div class="row mb-3" v-if="$router.currentRoute.name !== 'SingleTask' && $router.currentRoute.query.frompaint">
            <div class="col-md-12">
                <div class="d-flex mb-3">
                    <button class="btn btn-md mr-2 orange ld-over d-block actions" @click="newDefaultTask('With Customer', $event)">
                        With Customer<div class="ld ld-spinner ld-spin"></div>
                    </button>
                    <button class="btn btn-md mr-2 orange ld-over d-block actions" @click="newDefaultTask('Cleaning', $event)">
                        Cleaning<div class="ld ld-spinner ld-spin"></div>
                    </button>
                    <button class="btn btn-md mr-2 orange ld-over d-block actions" @click="newDefaultTask('Booth', $event)">
                        Booth<div class="ld ld-spinner ld-spin"></div>
                    </button>

                    <button class="btn btn-md ml-auto mr-2 green ld-over d-block actions" @click="removeCompleted($event)">
                        Remove Completed<div class="ld ld-spinner ld-spin"></div>
                    </button>
                </div>
                <div class="card">
                    <div class="card-header py-1">
                        <div class="new-task-wrap">
                            <input type="text" class="header-input form-control" placeholder="Add Task..." :value="new_task" @input="e => new_task = e.target.value" @keydown.tab="addTask($event)">
                            <button class="btn btn-sm ld-over" v-if="new_task.length > 0" @click="addTask($event)">
                                Add Task
                                <div class="ld ld-spinner ld-spin"></div>
                            </button>
                        </div>
                    </div>
                    <div class="card-body" v-if="toggleNewTask">
                        <div class="form-group">
                            <label>Notes</label>
                            <wysiwyg :content="wysiwyg_content" ref="new_task_desc"></wysiwyg>
                        </div>
                        <div class="form-group mt-2">
                            <label>Priority</label>
                            <select class="form-control" v-model="priority">
                                <option>High</option>
                                <option selected>Normal</option>
                                <option>Low</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3" v-if="this.$router.currentRoute.name !== 'SingleTask' && !$router.currentRoute.query.frompaint" v-show="!onBreak">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header py-1">
                        <div class="new-task-wrap">
                            <input type="text" class="header-input form-control" placeholder="Add Task..." :value="new_task" @input="e => new_task = e.target.value" @keydown.tab="addTask($event)">
                            <button class="btn btn-sm ld-over" v-if="new_task.length > 0" @click="addTask($event)">
                                Add Task
                                <div class="ld ld-spinner ld-spin"></div>
                            </button>
                        </div>
                        <div class="custom-control custom-switch float-right mt-2">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="task_top">
                            <label class="custom-control-label" for="customSwitch1" v-if="task_top">Top</label>
                            <label class="custom-control-label" for="customSwitch1" v-else>Bottom</label>
                        </div>
                        <div class="float-right mr-4 mt-2" @click="toggleNewTask = !toggleNewTask"><i class="fa fa-caret-down"></i></div>
                    </div>
                    <div class="card-body" v-if="toggleNewTask">
                        <div class="form-group">
                            <label>Notes</label>
                            <wysiwyg :content="wysiwyg_content" ref="new_task_desc"></wysiwyg>
                        </div>
                        <div class="form-group mt-2">
                            <label>Priority</label>
                            <select class="form-control" v-model="priority">
                                <option>High</option>
                                <option selected>Normal</option>
                                <option>Low</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <draggable id="task_list" v-model="tasks" v-bind="dragOptions" @end="onUpdate" tag="ul" ref="drag_list" v-show="!onBreak">
            <transition-group ref="transition_list">
            <li class="mb-2 ghost" v-for="task in tasks" v-bind:key="task.id" v-if="canShowTask(task)">
                <table-task-view  :task="task" :allusers="users" :current_user="current_user" ref="user_task"></table-task-view>
            </li>
            </transition-group>
        </draggable>

        <div class="text-center mt-4" v-if="onBreak">
            <h1 class="animate__animated animate__pulse animate__infinite">CURRENTLY ON BREAK!</h1>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "app",
        mounted() {
            if(this.$router.currentRoute.name == 'SingleTask') {
                this.getTask(this.$router.currentRoute.params.id);
            } else {
                setTimeout(() => {
                    if(this.$router.currentRoute.query.frompaint) {
                        this.getPaintTasks(true);
                    } else {
                        this.getTasks();
                    }
                    this.breakStatus();
                }, 500);

                if(!this.$router.currentRoute.query.frompaint) {
                    setTimeout(() => {
                        this.getNewTasks();
                    }, 5000);
                }
            }

            setTimeout(() => {
                if(this.onBreak) {
                    var buttons = document.getElementsByTagName('button');
                    for (let i = 0; i < buttons.length; i++) {
                        let button = buttons[i];
                        let type = button.getAttribute('type') || 'submit';
                        if(!button.classList.contains('stopBreakBtn')) {
                            button.disabled = true
                        }
                    }
                }
            }, 1000);
        },
        computed: {
            username(){
                if(this.$router.currentRoute.params.id) {
                    var usr = this.users.find(el => el.id == this.$router.currentRoute.params.id);
                    if(usr) {
                        return usr.nicename;
                    } else {
                        return null;
                    }
                } else {
                    if(this.$store.state.auth.user && this.$store.state.auth.user) {
                        return this.$store.state.auth.user.nicename;
                    } else {
                        return null;
                    }
                }
            },
            current_user(){
                if(this.$router.currentRoute.params.id) {
                    return this.$router.currentRoute.params.id;
                } else {
                    if(this.$store.state.auth.user) {
                        return this.$store.state.auth.user.id;
                    } else {
                        return null
                    }
                }
            },
            users(){
                return JSON.parse(localStorage.getItem('staff')) || [];
            }
        },
        data: function () {
            return {
                timer: 0,
                dragOptions: {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost",
                    dragClass: "drag",
                    forceFallback: true,
                    handle: ".sort-handle"
                },
                tempTasks: [],
                tasks: [],
                sortOrder: [],
                toggleNewTask: false,
                new_task: "",
                wysiwyg_content: "",
                task_top: true,
                isLoading: true,
                fullPage: true,
                priority: "Normal",
                lastChecked: moment().format('YYYY-MM-DD HH:mm:ss'),
                newTasksCount: 0,
                newTasks: [],
                onBreak: false
            }
        },
        watch: {
        },
        methods: {
            canShowTask(task) {
                if(this.$router.currentRoute.query && this.$router.currentRoute.query.frompaint) {
                    if(task.job_id == null && task.paint_task == 1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                };
            },
            getTasks(id){
                var self = this;
                axios.get(api_url + '/api/tasks/'+self.current_user).then(response => {
                    if(Array.isArray(response.data)){
                        self.tasks = response.data;
                        self.isLoading = false;
                    } else {
                        this.$toasted.error( 'Failed to get tasks!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                })
            },
            getPaintTasks(id){
                var self = this;
                axios.get(api_url + '/api/tasks/'+self.current_user +'/paint').then(response => {
                    if(Array.isArray(response.data)){
                        self.tasks = response.data;
                        self.isLoading = false;
                    } else {
                        this.$toasted.error( 'Failed to get tasks!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                })
            },
            getTask(id){
                var self = this;
                axios.get(api_url + '/api/task/'+id).then(response => {
                    self.tasks.push(response.data);
                    self.isLoading = false;
                })
                .catch(e => {
                })
            },
            breakStatus(id){
                var self = this;
                axios.get(api_url + '/api/task/break/status').then(response => {
                    if(response.data == "On Break") {
                        this.onBreak = true;
                    } else {
                        this.onBreak = false;
                    }
                })
                .catch(e => {
                })
            },
            onUpdate(evt, originalEvent) {
                var self = this;
                var newOrder = [];
                this.tasks.forEach(element => {
                    newOrder.push(element.id);
                });
                self.sortOrder = newOrder;
                
                axios.post(api_url + '/api/tasks/reorder',{
                    current_user: self.current_user,
                    sort: self.sortOrder
                }).then(response => {
                    
                })
                .catch(e => {
                })
            },
            addTask(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                var title = this.new_task;
                var desc = this.wysiwyg_content;
                var task_top = this.task_top;
                var task_pos = 'Top';
                if(this.wysiwyg_content == "<p></p>") {
                    desc = "";
                }

                if(task_top == true) {
                    task_pos = 'Top';
                } else {
                    task_pos = 'Bottom';
                }

                if(self.toggleNewTask == false) {
                    desc = "";
                }
                var isPaintTask = false;
                if(this.$router.currentRoute.query && this.$router.currentRoute.query.frompaint) {
                    isPaintTask = true;
                }
                axios.post(api_url + '/api/task/new',{
                    current_user: self.current_user,
                    title: title,
                    note: desc,
                    position: task_pos,
                    priority: self.priority,
                    isPaintTask: isPaintTask
                }).then(response => {
                    if(response.data == "Success"){
                        self.new_task = "";
                        self.wysiwyg_content = "";
                        self.toggleNewTask = false;
                        self.getTasks();
                        self.toggleLoader(event, 'stop');
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to add task!', {icon:'exclamation-triangle'});
                });
            },
            newDefaultTask(title, event) {
                var self = this;
                self.toggleLoader(event, 'start');
                var title = title;
                var desc = "";
                var task_top = this.task_top;
                var task_pos = 'Top';
                if(task_top == true) {
                    task_pos = 'Top';
                } else {
                    task_pos = 'Bottom';
                }

                axios.post(api_url + '/api/task/new/paint',{
                    current_user: self.current_user,
                    title: title,
                    note: desc,
                    position: task_pos,
                    priority: self.priority,
                    isPaintTask: true
                }).then(response => {
                    if(response.data == "Success"){
                        self.new_task = "";
                        self.wysiwyg_content = "";
                        self.toggleNewTask = false;
                        self.getPaintTasks();
                        self.toggleLoader(event, 'stop');
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to add task!', {icon:'exclamation-triangle'});
                });
            },
            removeCompleted(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/remove/completed',{
                    current_user: self.current_user,
                    tasks: self.tasks,
                }).then(response => {
                    if(response.data == "Success"){
                        self.getTasks();
                        self.toggleLoader(event, 'stop');
                    } else {
                        this.$toasted.error( 'Failed to remove completed tasks!', {icon:'exclamation-triangle'});
                    }
                })
                .catch(e => {
                    this.$toasted.error( 'Failed to remove completed tasks!', {icon:'exclamation-triangle'});
                })
            },
            toggleLoader(event, status) {
                var button = event.target.closest('button');
                if(status == "stop") {
                    $(button).removeClass('running');
                } else if(status == "start") {
                    $(button).addClass('running');
                }
            },
            getNewTasks(){
                var self = this;
                setInterval(() => {
                    var checkTime = this.lastChecked;
                    axios.post(api_url + '/api/tasks/'+self.current_user+'/latest',{
                        time: checkTime
                    }).then(response => {
                        self.lastChecked = moment().format('YYYY-MM-DD HH:mm:ss');
                        if(response.data !== "") {
                            var newtask = false;
                            if(response.data.length !== this.tasks.length) {
                                this.newTasksCount = response.data.length - this.tasks.length;
                                this.newTasks = response.data;
                            }
                        }
                    })
                    .catch(e => {
                        this.$toasted.error( 'Failed to get tasks!', {icon:'exclamation-triangle'});
                    })
                }, 30000);
            },
            updateTasks(){
                var conf = confirm('This will refresh your tasklist, any unsaved notes will be refreshed. If you are sure you want to refresh the list then click "OK"');
                if(conf){
                    this.tasks = this.newTasks;
                    this.newTasks = [];
                    this.newTasksCount = 0;
                }
            },
            fagBreak(event) {
                var conf = confirm('Go on break?');
                if (conf) {
                    var self = this;
                    this.toggleLoader(event, 'start');
                    axios.post(api_url + '/api/task/break', {reason: 'Break'}).then(response => {
                        self.toggleLoader(event, 'stop');
                        location.reload();
                    })
                    .catch(e => {
                        self.toggleLoader(event, 'stop');
                        this.$toasted.error( 'Failed!', {icon:'exclamation-triangle'});
                    })
                }
            },
            fagBreakStop(event) {
                var self = this;
                this.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/break/stop').then(response => {
                    self.toggleLoader(event, 'stop');
                    location.reload();
                })
                .catch(e => {
                    self.toggleLoader(event, 'stop');
                    this.$toasted.error( 'Failed!', {icon:'exclamation-triangle'});
                })
            },
        }
    }

    function order( a, b ) {
        var order_a;
        var order_b;
        order_a = a.order;
        order_b = b.order;

        if ( order_a < order_b ){
            return -1;
        }
        if ( order_a > order_b ){
            return 1;
        }
    }
</script>

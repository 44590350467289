import AuthService from "../authentication/services/auth.service";
import Cookies from "js-cookie";
var ckie = unescape(Cookies.get("user") || "null");
const user = JSON.parse(ckie);
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		login({ commit }, user) {
			return AuthService.login(user).then(
				(user) => {
					commit("loginSuccess", user.data.user);
					return Promise.resolve(user.data.user);
				},
				(error) => {
					commit("loginFailure");
					return Promise.reject(error);
				}
			);
		},
		logout({ commit }) {
			AuthService.logout();
			commit("logout");
		},
		register({ commit }, user) {
			return AuthService.register(user).then(
				(response) => {
					commit("registerSuccess");
					return Promise.resolve(response.data);
				},
				(error) => {
					commit("registerFailure");
					return Promise.reject(error);
				}
			);
		},
	},
	mutations: {
		loginSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		loginFailure(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		logout(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		registerSuccess(state) {
			state.status.loggedIn = false;
		},
		registerFailure(state) {
			state.status.loggedIn = false;
		},
	},
};

<template>
    <nav class="navbar navbar-expand-md sticky-top navbar-light bg-white shadow-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img src="../assets/images/logo.png" height="30px"/>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="/"><i class="fas fa-tasks"></i> Tasks</a>
                    </li>
                    <template v-if="$store.state.auth.user.manager == true || $store.state.auth.user.manager == 'Y'">
                        <li class="nav-item">
                            <a class="nav-link" href="/multiview"><i class="fas fa-cubes"></i> Multiview</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/settings"><i class="fas fa-cogs"></i> Settings</a>
                        </li>
                    </template>
                    <li class="nav-item">
                        <a class="nav-link" href="/racelist"><i class="fas fa-tasks"></i> Racelists</a>
                    </li>

                    <template v-if="$router.currentRoute.name == 'Racelist'">
                        <li class="nav-item">
                            <a class="nav-link" href="/racelist/new"><i class="fas fa-plus"></i> New Racelist</a>
                        </li>
                        
                        <li class="nav-item">
                            <a class="nav-link" href="/racelist/defaults"><i class="fas fa-star"></i> Defaults</a>
                        </li>
                    </template>
                </ul>

                <ul class="navbar-nav ml-auto">
                    <li class="nav-item" v-if="!user">
                        <a class="nav-link" href="login">Login</a>
                    </li>

                    <li class="nav-item mr-3 mt-1 search" v-if="user">
                        <search :current_user="user"></search>
                    </li>

                    <li class="nav-item dropdown" v-if="user">
                        <a id="navbarDropdown" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img :src="user.image_path" class="avatar mr-1"> {{user.nicename}} <span class="caret"></span>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                            <a class="dropdown-item" @click="logout">
                                Logout
                            </a>

                            <form id="logout-form" action="logout" method="POST" style="display: none;"></form>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
</template>

<script>
export default {
    mounted(){
        console.log(this.$router)
    },
    data: () => ({
        test :"hello"
    }),
    methods: {
        logout(){
            if (window.location.hostname == "localhost") {
                this.$cookies.remove('user', null, 'localhost');
            } else {
                this.$cookies.remove('user', null, 'darkside-developments.com');
            }
            localStorage.clear();
            location.reload();
        }
    },
    computed: {
        user() {
            if(this.$store.state.auth.user) {
                return this.$store.state.auth.user;
            } else {
                return null
            }
        },
    }
}
</script>

<style>

</style>
<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <task-settings 
                :users="staff"
                :multiuser_tasks="$store.state.auth.user.tasks_multiview"
                ></task-settings>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        recurring_tasks: [],
        default_tasks: []
    }),
    mounted() {
        /*axios.get(api_url + '/api/tasks/info/settings').then((resp) => {
            this.recurring_tasks = resp.data.recurring;
            this.default_tasks = resp.data.default;
        });*/
    },
    computed: {
        staff() {
            return JSON.parse(localStorage.getItem('staff')) || []
        }
    }
}
</script>

<style>

</style>
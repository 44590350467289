<template>
<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-md-12 alltasks">
            <h3 class="mb-3"><i class="fas fa-tasks"></i> {{current_user.nicename}} - Deleted Tasks</h3>
            <div class="pb-5 position-relative">
                <loading :active.sync="isLoading" 
                :can-cancel="false"
                loader="LineBar"
                :is-full-page="fullPage"></loading>

                <div id="task_list" @scroll="loadMore">
                    <div v-for="task in tasks" :key="task.id" class="mb-3">
                        <table-task-view :task="task" :allusers="users" :current_user="current_user.id" ref="user_task"></table-task-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    import moment from 'moment';
    export default {
        mounted() {
            //window.addEventListener('scroll', this.loadMore);
            this.tasks = [];
            this.getTasks();
        },
        data: function () {
            return {
                tempTasks: [],
                tasks: [],
                sortOrder: [],
                toggleNewTask: false,
                new_task: "",
                wysiwyg_content: "",
                task_top: true,
                isLoading: true,
                fullPage: true,
                priority: "Normal",
                currentPage: 1,
                maxPage: 1,
            }
        },
        watch: {
        },
        methods: {
            getTasks: function(){
                var self = this;
                self.isLoading = true;
                axios.get(api_url + '/api/tasks/'+self.current_user.id+'/deleted?page='+this.currentPage).then(response => {
                    self.tasks = self.tasks.concat(response.data.data);
                    self.currentPage = response.data.current_page
                    self.maxPage = response.data.last_page
                    self.isLoading = false;
                })
                .catch(e => {
                })
            },
            onUpdate: function (event) {
                var self = this;
                this.tasks.splice(event.newIndex, 0, this.tasks.splice(event.oldIndex, 1)[0]);
                
                var newOrder = [];
                this.tasks.forEach(element => {
                    newOrder.push(element.id);
                });
                self.sortOrder = newOrder;
                
                axios.post(api_url + '/api/tasks/reorder',{
                    current_user: self.current_user.id,
                    sort: self.sortOrder
                }).then(response => {
                })
                .catch(e => {
                })

            },
            addTask: function() {
                var self = this;
                var title = this.new_task;
                var desc = this.wysiwyg_content;
                var task_top = this.task_top;
                var task_pos = 'Top';
                if(this.wysiwyg_content == "<p></p>") {
                    desc = "";
                }

                if(task_top == true) {
                    task_pos = 'Top';
                } else {
                    task_pos = 'Bottom';
                }

                axios.post(api_url + '/api/task/new',{
                    current_user: self.current_user.id,
                    title: title,
                    note: desc,
                    position: task_pos,
                    priority: self.priority
                }).then(response => {
                    if(response.data == "Success"){
                        this.new_task = "";
                        this.wysiwyg_content = "";
                        self.getTasks();
                    }
                })
                .catch(e => {
                })
            },
            removeCompleted: function(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/remove/completed',{
                    current_user: self.current_user.id,
                }).then(response => {
                    if(response.data == "Success"){
                        self.getTasks();
                        self.toggleLoader(event, 'stop');
                    }
                })
                .catch(e => {
                })
            },
            toggleLoader: function (event, status) {
                var button = event.target.closest('button');
                if(status == "stop") {
                    $(button).removeClass('running');
                } else if(status == "start") {
                    $(button).addClass('running');
                }
            },
            loadMore(e) {
                let scrollTop = e.target.scrollTop
                let clientHeight = e.target.offsetHeight
                let scrollHeight = e.target.scrollHeight
                if (!this.isLoading && scrollTop + clientHeight >= scrollHeight) {
                    if(this.maxPage !== this.currentPage) {
                        this.currentPage++;
                        this.getTasks();
                    }
                }
            }
        },
        computed: {
            users(){
                return JSON.parse(localStorage.getItem('staff')) || [];
            },
            current_user(){
                return this.users.find(el => this.$router.currentRoute.params.id == el.id);
            }
        },
        onUnmounted() {
            //window.removeEventListener('scroll', this.loadMore);
        }
    }
</script>

<style lang="scss">
    #task_list {
        height: 80vh;
        overflow-y: auto;
        padding-bottom: -20px;
    }
</style>
<template>
    <div>
        <h4 class="mb-3">New Task</h4>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label><strong>User/s</strong></label>
                                <select class="select2" id="userlist" v-model="selectedUsers">
                                    <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{user.nicename}}</option>
                                </select>
                            </div>    
                        </div>
                        
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label><strong>Priority</strong></label>
                                <select class="form-control" v-model="priority">
                                    <option>High</option>
                                    <option>Normal</option>
                                    <option>Low</option>
                                </select>
                            </div>    
                        </div>
                        
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label><strong>Title</strong></label>
                                <input class="form-control" placeholder="Title..." v-model="title">
                            </div>    
                        </div>
                        
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label><strong>Description</strong></label>
                                <wysiwyg :content="wysiwyg_content" ref="new_task_desc"></wysiwyg>
                            </div>    
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-12">
                                <button class="btn btn-dark btn-block" @click="add_task">Add Task</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    props: ['users'],
    mounted: function() {
        var self = this;
        $(document).ready(function() {
            $('#userlist').select2({
                multiple: true,
                placeholder: "Select user/s...",
            });

            $('#userlist').on("select2:select", function (e) {
                self.selectedUsers = $('#userlist').select2('val');
            });
        });
    },
    data: function(){
        return {
            selectedUsers: [],
            title: "",
            wysiwyg_content: "",
            delay_until: null,
            priority: 'Normal'
        }
    },
    methods: {
        add_task:function(){
            var self = this;
            axios.post(api_url + '/api/task/add',{
                title: self.title,
                notes: self.wysiwyg_content,
                users: self.selectedUsers,
                priority: self.priority,
            }).then(response => {
                if(response.data == "Success") {
                    alert('Task Added!')
                    location.reload();
                }
            })
            .catch(e => {
            })
        }
    },
    filters: {
        user: ((val, users) => {
            var obj = users.find(item=>item.id==val);
            return obj.nicename;
        }),
        date: ((val) => {
            return moment(val).format("DD-MM-YYYY");
        })
    }
}
</script>
<template>
    <div class="position-relative mt-1 ml-2">
        <button class="btn btn-sm green ld-over" @click="removeCompleted($event)">
            Remove All Completed
            <div class="ld ld-spinner ld-spin"></div>
        </button>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['views'],
        mounted() {
        },
        data: function () {
            return {
            }
        },
        watch: {
        },
        methods: {
            removeCompleted: function(event) {
                var self = this;
                self.toggleLoader(event, 'start');
                axios.post(api_url + '/api/task/remove/completed/multiview',{
                    current_user: self.current_user,
                    users: self.views
                }).then(response => {
                    self.toggleLoader(event, 'stop');
                    if(response.data == "Success"){
                        self.views.forEach(usr => {
                            var view = "multiview-"+usr;
                            this.$parent.$refs[view].getTasks();
                        });
                    }
                })
                .catch(e => {
                    self.toggleLoader(event, 'stop');
                })
            },
            toggleLoader: function (event, status) {
                var button = event.target.closest('button');
                if(status == "stop") {
                    $(button).removeClass('running');
                } else if(status == "start") {
                    $(button).addClass('running');
                }
            },
        }
    }
</script>
<template>
    <div id="search-input-wrap">
        <div class="search">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="search-addon"><i class="fas fa-search"></i></span>
                </div>
                <input type="text" id="search-input" class="form-control" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" @focus="extend" @blur="leave" v-model="search" @keyup="searchTasks">
            </div>
        </div>
        <div class="overlay" v-if="searchResults.length > 0">
            <div class="container-fluid d-block">
                <div class="my-3">
                    <h3 class="d-inline-block">{{searchedTerm}}({{searchResults.length}})</h3>
                    <button class="btn btn-sm float-right" @click="closeResults"><i class="fas fa-times"></i></button>
                </div>
                <div class="mb-3 ghost" v-for="task in searchResults" v-bind:key="task.id">
                    <table-task-view :auth="current_user" :task="task" :allusers="users" :current_user="current_user" :deleted="task.deleted"></table-task-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['current_user'],
    data: function() {
        return {
            search: "",
            searchedTerm: "",
            searchResults: [],
            users: [],
        }
    },
    methods: {
        closeResults: function(){
            this.search = "";
            this.searchedTerm = "";
            this.searchResults = [];
        },
        extend: function() {
            if ($(window).width() > 960) {
                var wrap = $('#search-input-wrap').closest('.navbar-nav');
                wrap.animate({
                    width: '60%',
                }, 300, function() {
                    
                });
            }
        },
        searchTasks: _.debounce(function() {
            var self = this;
            if(self.search.length > 3) {
                self.searchedTerm = self.search;
                axios.post(api_url + '/api/task/search',{
                    query: self.search
                }).then(response => {
                    self.searchResults = response.data.tasks;
                    self.users = response.data.users;
                })
                .catch(e => {
                })
            }
        },500),
        leave: function(){
            var self = this;
            if ($(window).width() > 960) {
                var wrap = $('#search-input-wrap').closest('.navbar-nav');
                wrap.animate({
                    width: '337px',
                }, 300, function() {
                    self.search = "";
                });
            } else {
                self.search = "";
            }
        }
    },
    filters: {
        username: ((val, users) => {
            var obj = users.find(item=>item.id==val);
            if(obj !== undefined) {
                return obj.nicename;
            } else {
                return val;
            }
        }),
    }
}
</script>
<template>
    <div class="w-100">
        <h4 class="mb-3">New Default Race Check Task</h4>
        <div class="row mb-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label><strong>Title</strong></label>
                                <input class="form-control" placeholder="Title..." v-model="title">
                            </div>    
                        </div>
                        
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label><strong>Description</strong></label>
                                <wysiwyg :content="wysiwyg_content" ref="new_task_desc"></wysiwyg>
                            </div>    
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-12">
                                <label><strong>Order</strong></label>
                                <input type="number" class="form-control" placeholder="Order..." v-model="order">
                            </div>    
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-12">
                                <button class="btn btn-dark btn-block" @click="add_task($event)">Add Default Task</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    mounted: function() {
    },
    data: function(){
        return {
            title: "",
            wysiwyg_content: "",
            order: 0
        }
    },
    methods: {
        add_task:function(e){
            e.target.disabled = true;
            var self = this;
            axios.post(api_url + '/api/racelist/defaults/new',{
                title: self.title,
                notes: self.wysiwyg_content,
                order: self.order
            }).then(response => {
                if(response.data.success == true) {
                    alert('Task Added!')
                    location.reload();
                }
                e.target.disabled = false;
            })
            .catch(e => {
            })
        }
    },
    filters: {
        date: ((val) => {
            return moment(val).format("DD-MM-YYYY");
        })
    }
}
</script>
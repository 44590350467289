<template>
    <div style="display: flex; flex-wrap: wrap; width:100%">
        <div class="view" v-for="user in multitasks" v-bind:key="user" :id="'multiview-'+user" :class="{'two_views': multitasks.length == 2}">
            <multiview-table-tasks 
            :ref="'multiview-'+user" 
            :current_user="user" 
            :users="users" 
            :auth="auth">
            </multiview-table-tasks>
            <div class="resizer bottom-right" :id="'multiview-resizer-'+user" @mousedown="makeDivResize($event)"></div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        props: ['users','current_user','auth'],
        name: "app",
        data: () => ({
            multitasks: []
        }),
        mounted(){
            this.getMultiviewUsers();
        },
        methods: {
            makeDivResize(e) {
                console.log(e)
                this.$root.makeResizableDiv(e)
            },
            getMultiviewUsers() {
                axios.get(api_url + '/api/tasks/multiview').then((resp) => {
                    this.multitasks = resp.data;
                });
            }
        },
        computed: {
        }
    }
</script>